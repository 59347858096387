import './header2.css';
import React from 'react';
import {Helmet} from"react-helmet";
import headerlogo from '../images/mainpageimg/headerlogo.png'

function Header2() {
  

  return (
    <div>
         <Helmet>
     < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
     <meta charset="UTF-8"/>
       </Helmet>
     
<div className=''>

<div className='headermain'>
<a href='/'>
<img className='headerlogo2' src={headerlogo} alt='headerlogo'/> </a>
<a href='/' className='linkheader2'>HOME</a>
<div className="dropdown">
  <button className="nbartext2contact1" >PRODUCTS&nbsp;&nbsp;
        <i className="fa fa-caret-down"></i>
  </button>
  <div className="dropdown-contenth3" style={{marginLeft:"29%",marginTop:"5%"}}>
  <a href="/voip-phone" style={{color:"#104E71",fontWeight:"400"}}>VOIP PHONE</a>
  <a href="/business-phone-system" style={{color:"#104E71",fontWeight:"400"}}>BUSINESS PHONE SYSTEM</a>
  <a href="/team-chat-system" style={{color:"#104E71",fontWeight:"400"}}>TEAM CHAT SYSTEM</a>
  </div>
</div>
<div className="dropdown">
  <button className="nbartext2contact1" >SOLUTIONS&nbsp;&nbsp;
        <i className="fa fa-caret-down"></i>
  </button>
  <div className="dropdown-contenth2" style={{marginLeft:"39%",marginTop:"4.9%"}}>
  <h4   style={{color:"#104E71",fontWeight:"500",marginLeft:"5%",marginTop:"8%",fontFamily:"Oswald",fontSize:"1.4vw"}}>BY AUDIENCE</h4>
   <a href="/solutions" style={{color:"#104E71",fontWeight:"400"}}>CORPORATIONS	</a>
  <a href="/small-business-solution" style={{color:"#104E71",fontWeight:"400"}}>SMALL BUSINESSES </a>
  <a href="/startup" style={{color:"#104E71",fontWeight:"400"}}>STARTUPS</a>
  </div>
</div>
<div className="dropdown">
<a href='/whyvibes' className="nbartext" style={{border:"none",backgroundColor:"white",marginTop:"3.1%"}} id='fs'>WHY VIBES &nbsp;
        <i className="fa fa-caret-down"></i>
  </a>
  <div className="dropdown-content" style={{marginLeft:"50%",marginTop:"5.1%"}}>
  <a href='/blogs' style={{color:"#104E71",fontWeight:"400",marginTop:"10%"}}>BLOG</a>
  <a href='/resources' style={{color:"#104E71",fontWeight:"400",marginTop:"10%"}}>RESOURCES</a>

  </div>
</div> 

<a href='/contact' className='linkheader2' style={{marginLeft:"2%"}}>CONTACT US</a>


<a href="tel:346-500-6555" className='linkheader2' style={{marginLeft:"5%"}}>Call Us : 346-500-6555</a>
<div className='gettext' style={{visibility:"hidden"}}>GET STARTED</div>

</div>
</div>


    </div>
  );
}

export default Header2;
