import './Headermb.css';
import React from 'react';
import {Helmet} from"react-helmet";

import headerlogo from '../images/mainpageimg/headerlogo.png'

function Headermb() {
  

   

  return (
    <div>
         <Helmet>
     < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
     <meta charset="UTF-8"/>
     <link href='https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css' rel='stylesheet'/>

       </Helmet>
     
<div id="header__con">
    <div className="navbar" style={{display:"flex",alignItems:"center"}}>
    
 <img className='' id='log__con__head' style={{marginLeft:"5%",marginTop:"%"}} src={headerlogo} alt='headerlogo'/> 
<button type="button" log__con__head class="close" style={{marginTop:"-15%",width:"%"}} id='closebtn' data-dismiss="modal">&times;</button>


     
    </div>
 
 
</div>


    </div>
  );
}

export default Headermb;
