import React from 'react'
import './footer.css'
import headerlogo from '../images/mainpageimg/headerlogo.png'

 const Footer =()=> {
  return (
    <>
      <section className="footer__section">
      <div className="footer__width">
      <div className="footer__row">

        <div className='footer_colb'>
        <img  src={headerlogo} alt='ecircle2'/> 

          <p>Vibes Communications is a leading provider of VoIP solutions. We enable businesses of all sizes to connect and collaborate more effectively, whether they are in the office or on the go. Our flexible, cost-effective solutions allow businesses to communicate the way they want, on any device, anywhere, anytime. From an all-in-one cloud phone system with team messaging and video conferencing to a complete contact center, we offer a variety of services to help your business succeed. </p>
        </div>
 
        <div className='footer_col' >
        <h3>Pages</h3>
        <br/>
        <a href='/whyvibes'>WHY VIBES&nbsp;?</a>
        <br/><br/>
        <a href='/blogs' >BLOG</a>
        <br/><br/>
        <a href='/term-condoitions' >TERMS</a>
        <br/><br/>
        <a href='/Privacy-policy' >PRIVACY POLICY</a>
        <br/><br/>
        <a href='/contact' >CONTACT US</a>
        
        </div>
 
        <div className='footer_col' >
        <h3>Contact</h3>
        <div className='contact_detil'>
        <i class="fa fa-phone" aria-hidden="true"></i>
        <span><a href="tel:346-500-6555" className='flink'>346-500-6555</a></span>
        </div>
        <div className='contact_detil'>
        <i class="fa fa-envelope" aria-hidden="true"></i>

        <span><a href='mailto:team@vibescommunications.com'  >team@vibescommunications.com</a></span>        
        </div>
        <div className='contact_detil'>
        <i class="fa fa-map-marker" aria-hidden="true"></i>
        <span><p>2800 Post Oak Blvd Suite 4100, Houston, Texas 77056, USA
</p>
</span></div>     

        </div>
 
 <div className='footer_col'>
            <h3>Social Media</h3>
          <div className='social__media'>
          <a href='https://www.facebook.com/VibesCommunicationsUSA' target="_blank"><i class="fa fa-facebook-f" id='social1'></i></a>
          <a href='https://twitter.com/VibesCommUSA' target="_blank"><i class="fa fa-twitter" id='social2'></i></a>
          <a href='https://www.linkedin.com/company/vibes-communications/' target="_blank"><i class="fa fa-linkedin"  id='social4'></i></a>
          <a href='https://www.instagram.com/vibescommunicationsusa/' target="_blank"><i class="fa fa-instagram"  id='social3'></i></a>

          </div>
        </div>
 
        </div>
        <p>Copyright © 2023 Vibes Communications | All Rights Reserved</p>
        </div>

      </section> 
    </>
  )
}
export default Footer;