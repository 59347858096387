

const products = [
  {
    id: "01",
    productName: "Essential",
    pname2:"Easy, quick start plan.",
    category: "a",
    subcategory: "ab",
    
    p1:"Save 29% ",
    p2:"$22.95",

     description:['Unlimited voice & video calling','Voicemail','Toll-free numbers','Unlimited internet fax','Outlook/Google Contacts Integrations','Call handling','Call queues','Team messaging with document sharing','Mix and match plans','24x7 customer support','Interactive meetings with live emoji reactions, instant polls, hand raising, virtual backgrounds, and breakout rooms']
  

  },

  {
    id: "02",
    productName: "Professional",
    pname2:"Great choice for smaller teams.",
    category: "a",
    subcategory: "ab",
  
    p1:"Save 29% ",
    p2:"$25.95",
    description:['Everything in Essential, plus:','Unlimited conference calls, 40 participants','Unlimited video conferencing','Screensharing','Auto attendant (multi-level)','Mobile and Desktop SMS/MMS','Presence detection']

  },

  {
    id: "03",
    productName: "Enterprise",
    pname2:"Great for small & medium teams.",
    category: "a",
    subcategory: "ab",
    
    p1:"Save 29% ", 
    p2:"$36.95",
    description:['Everything in Essential, plus:','Unlimited participants on voice & video conferencing','Call recording','Unlimited video conference recording','Voicemail transcription','Microsoft Teams/Custom Integrations','Single Sign On','Barge, monitor, and whisper']

     },
  

     {
        id: "04",
        productName: "Essential",
        pname2:"Easy, quick start plan.",
        category: "b",
        subcategory: "ab",
        
        p1:"Save 29% ",
        p2:"$19.95",
        description:['Unlimited voice & video calling','Voicemail','Toll-free numbers','Unlimited internet fax','Outlook/Google Contacts Integrations','Call handling','Call queues','Team messaging with document sharing','Mix and match plans','24x7 customer support','Interactive meetings with live emoji reactions, instant polls, hand raising, virtual backgrounds, and breakout rooms']
     
      },
    
      {
        id: "05",
        productName: "Professional",
        pname2:"Great choice for smaller teams.",
        category: "b",
    subcategory: "ab",
    
    p1:"Save 29% ",
        p2:"$22.95",
        description:['Everything in Essential, plus:','Unlimited conference calls, 40 participants','Unlimited video conferencing','Screensharing','Auto attendant (multi-level)','Mobile and Desktop SMS/MMS','Presence detection']

      },
    
      {
        id: "06",
        productName: "Enterprise",
        pname2:"Great for small & medium teams.",
        category: "b",
        subcategory: "ab",
        
        p1:"Save 29% ",
        p2:"$33.95",
        description:['Everything in Essential, plus:','Unlimited participants on voice & video conferencing','Call recording','Unlimited video conference recording','Voicemail transcription','Microsoft Teams/Custom Integrations','Single Sign On','Barge, monitor, and whisper']
      },
      

     
         {
            id: "07",
            productName: "Essential",
            pname2:"Easy, quick start plan.",
            category: "c",
    subcategory: "ab",
            p2:"$24.95",
            description:['Everything in Essential, plus:','Unlimited voice & video calling','Voicemail','Toll-free numbers','Unlimited internet fax','Outlook/Google Contacts Integrations','Call handling','Call queues','Team messaging with document sharing','Mix and match plans','24x7 customer support','Interactive meetings with live emoji reactions, instant polls, hand raising, virtual backgrounds, and breakout rooms']
          },
        
          {
            id: "08",
            productName: "Professional",
            pname2:"Great choice for smaller teams.",
            category: "c",
    subcategory: "ab",
            p2:"$28.95",
            description:['Everything in Essential, plus:','Unlimited conference calls, 40 participants','Unlimited video conferencing','Screensharing','Auto attendant (multi-level)','Mobile and Desktop SMS/MMS','Presence detection']
        
          },
        
          {
            id: "09",
            productName: "Enterprise",
            pname2:"Great for small & medium teams.",
            category: "c",
    subcategory: "ab",
            p2:"$39.95",
            description:['Everything in Essential, plus:','Unlimited participants on voice & video conferencing','Call recording','Unlimited video conference recording','Voicemail transcription','Microsoft Teams/Custom Integrations','Single Sign On','Barge, monitor, and whisper']
          },    


             
     {
        id: "10",
        productName: "Essential",
        pname2:"Easy, quick start plan.",
        category: "d",
    subcategory: "ab",
        p2:"$21.95",
    
        description:['Unlimited voice & video calling','Voicemail','Toll-free numbers','Unlimited internet fax','Outlook/Google Contacts Integrations','Call handling','Call queues','Team messaging with document sharing','Mix and match plans','24x7 customer support','Interactive meetings with live emoji reactions, instant polls, hand raising, virtual backgrounds, and breakout rooms']
 
      },
    
      {
        id: "11",
        productName: "Professional",
        pname2:"Great choice for smaller teams.",
        category: "d",
    subcategory: "ab",
        p2:"$25.95",
        description:['Everything in Essential, plus:','Unlimited conference calls, 40 participants','Unlimited video conferencing','Screensharing','Auto attendant (multi-level)','Mobile and Desktop SMS/MMS','Presence detection']
    
      },
    
      {
        id: "12",
        productName: "Enterprise",
        pname2:"Great for small & medium teams.",
        category: "d",
    subcategory: "ab",
        p2:"$38.95",
        description:['Everything in Essential, plus:','Unlimited participants on voice & video conferencing','Call recording','Unlimited video conference recording','Voicemail transcription','Microsoft Teams/Custom Integrations','Single Sign On','Barge, monitor, and whisper']
      },
     

         
         {
            id: "12",
            productName: "Essential",
            pname2:"Easy, quick start plan.",
            category: "a",
            subcategory: "aba",
            p2:"$29.95",
            description:['Unlimited voice & video calling','Voicemail','Toll-free numbers','Unlimited internet fax','Outlook/Google Contacts Integrations','Call handling','Call queues','Team messaging with document sharing','Mix and match plans','24x7 customer support','Interactive meetings with live emoji reactions, instant polls, hand raising, virtual backgrounds, and breakout rooms']
     
          },
        
          {
            id: "13",
            productName: "Professional",
            pname2:"Great choice for smaller teams.",
            category: "a",
            subcategory: "aba",
            p2:"$34.95",
            description:['Everything in Essential, plus:','Unlimited conference calls, 40 participants','Unlimited video conferencing','Screensharing','Auto attendant (multi-level)','Mobile and Desktop SMS/MMS','Presence detection']
          },
        
          {
            id: "14",
            productName: "Enterprise",
            pname2:"Great for small & medium teams.",
            category: "a",
            subcategory: "aba", 

            p2:"$44.95",
            description:['Everything in Essential, plus:','Unlimited participants on voice & video conferencing','Call recording','Unlimited video conference recording','Voicemail transcription','Microsoft Teams/Custom Integrations','Single Sign On','Barge, monitor, and whisper']
          },
          
        
             {
                id: "15",
                productName: "Essential",
                pname2:"Easy, quick start plan.",
                category: "b",
                subcategory: "aba",
                p2:"$28.95",
                description:['Unlimited voice & video calling','Voicemail','Toll-free numbers','Unlimited internet fax','Outlook/Google Contacts Integrations','Call handling','Call queues','Team messaging with document sharing','Mix and match plans','24x7 customer support','Interactive meetings with live emoji reactions, instant polls, hand raising, virtual backgrounds, and breakout rooms']

            
              },
            
              {
                id: "16",
                productName: "Professional",
                pname2:"Great choice for smaller teams.",
                category: "b",
            subcategory: "aba",
                p2:"$32.95",
                description:['Everything in Essential, plus:','Unlimited conference calls, 40 participants','Unlimited video conferencing','Screensharing','Auto attendant (multi-level)','Mobile and Desktop SMS/MMS','Presence detection','Everything in Essential, plus:']
            
              },
            
              {
                id: "17",
                productName: "Enterprise",
                pname2:"Great for small & medium teams.",
                category: "b",
                subcategory: "aba",
                p2:"$42.95",
                description:['Everything in Essential, plus:','Unlimited participants on voice & video conferencing','Call recording','Unlimited video conference recording','Voicemail transcription','Microsoft Teams/Custom Integrations','Single Sign On','Barge, monitor, and whisper','Everything in Professional, plus:']
              },
              
        
             
                 {
                    id: "18",
                    productName: "Essential",
                    pname2:"Easy, quick start plan.",
                    category: "c",
                    subcategory: "aba",
                    p1:"Save 29% ",
                
                    p2:"$16.95",
                
                    description:['Unlimited voice & video calling','Voicemail','Toll-free numbers','Unlimited internet fax','Outlook/Google Contacts Integrations','Call handling','Call queues','Team messaging with document sharing','Mix and match plans','24x7 customer support','Interactive meetings with live emoji reactions, instant polls, hand raising, virtual backgrounds, and breakout rooms']
        
                  },
                
                  {
                    id: "19",
                    productName: "Professional",
                    pname2:"Great choice for smaller teams.",
                    category: "c",
                    subcategory: "aba",
                    p1:"Save 29% ",
                    p2:"$19.95",
                    description:['Everything in Essential, plus:','Unlimited conference calls, 40 participants','Unlimited video conferencing','Screensharing','Auto attendant (multi-level)','Mobile and Desktop SMS/MMS','Presence detection']
                  },
                
                  {
                    id: "20",
                    productName: "Enterprise",
                    pname2:"Great for small & medium teams.",
                    category: "c",
            subcategory: "aba",
                    p1:"Save 29% ",
                    p2:"$30.95",
                    description:['Everything in Essential, plus:','Unlimited participants on voice & video conferencing','Call recording','Unlimited video conference recording','Voicemail transcription','Microsoft Teams/Custom Integrations','Single Sign On','Barge, monitor, and whisper']
                  },    
        
        
                     
             {
                id: "21",
                productName: "Essential",
                pname2:"Easy, quick start plan.",
                category: "d",
            subcategory: "aba",
                p1:"Save 33% ",
                p2:"$14.95",
              description:['Unlimited voice & video calling','Voicemail','Toll-free numbers','Unlimited internet fax','Outlook/Google Contacts Integrations','Call handling','Call queues','Team messaging with document sharing','Mix and match plans','24x7 customer support','Interactive meetings with live emoji reactions, instant polls, hand raising, virtual backgrounds, and breakout rooms']
            
              },
            
              {
                id: "22",
                productName: "Professional",
                pname2:"Great choice for smaller teams.",
                category: "d",
                subcategory: "aba",
                p1:"Save 29% ",
                p2:"$17.95",
                description:['Everything in Essential, plus:','Unlimited conference calls, 40 participants','Unlimited video conferencing','Screensharing','Auto attendant (multi-level)','Mobile and Desktop SMS/MMS','Presence detection']
            
              },
            
              {
                id: "23",
                productName: "Enterprise",
                pname2:"Great for small & medium teams.",
                category: "d",
            subcategory: "aba",
                p1:"Save 29% ",
                p2:"$28.95",
                description:['Everything in Essential, plus:','Unlimited participants on voice & video conferencing','Call recording','Unlimited video conference recording','Voicemail transcription','Microsoft Teams/Custom Integrations','Single Sign On','Barge, monitor, and whisper']
              },
             
        
];

export default products;
