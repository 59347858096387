import React from 'react'

import {motion} from "framer-motion"
import "./prduct-card.css";
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Contact4 from '../contact/contact4';



const ProductCard = ({item}) => {


  return (


    <Col lg='4' md='4' className='mb-2'>

          <div className="product__item">
        
        <div className='product__header'>
         <h2>{item.productName}</h2>
         <h3>{item.pname2}</h3>
        </div>
        
        <div className='product__circle'>
        <h3>{item.p1}</h3>
        <h2>{item.p2}</h2>
        <h3>{item.p3}</h3>
        </div>
            <br/>
        <div className='product__last'>
          <table >
          
            {item.description.map((pet, index) => (
              
              <tr >
                <td style={{border:"none"}}> &nbsp;&nbsp;<i  class="fa" style={{color:"#1AA260",fontSize:"15px"}}>&#xf058;&nbsp;</i> &nbsp;{pet}</td>
               </tr>
       
          ))}

          </table>
        </div>


    </div>
    <div className='select__plan' style={{cursor:"pointer"}} data-toggle="modal" data-target="#myModal">CONTACT A SALES REP</div>
<div class="modal fade" id="myModal">
    <div className="modal-dialog modal-xl" >
      <div class="modal-content" >
      <Contact4/> 
    </div>
  </div>
      </div>
    </Col>

     )
}


export default ProductCard
