import React from 'react';
import { useEffect,useState } from 'react';

import {Helmet} from"react-helmet";


import {Container,Row,Col} from "reactstrap";
import products from '../../assests/data/product'
import ProductsList from '../Pricing/ProductList';

import eslastv from '../images/esolutiions/eslastv.png'
import esfem3 from '../images/esolutiions/esfem3.png'

import bpssecure from '../images/mainpageimg/bpssecure.png'
import bpsservice from '../images/mainpageimg/bpsservice.png'
import bpssupport from '../images/mainpageimg/bpssupport.png'

import '../bphonesystem/bphonesystem.css';
import '../bphonesystem/bpscenter.css';
import '../esolution/esolution.css';
import '../esolution/escenter2.css';
import '../smallbusniessolution/sbs.css';
import '../smallbusniessolution/sbscenter1.css';
import vacll from '../images/new/vcall.png'
import ecircle1 from '../images/esolutiions/ecircle1.png'
import sbsline1formid1 from '../images/mainpageimg/sbslineformid1.png'
import bpscircle1 from '../images/mainpageimg/bpscircle1.png'
import bpslineh from '../images/mainpageimg/bpslineh.png'
import bpsskylogo3 from '../images/mainpageimg/bpsskylogo3.png'
import bpsman1 from '../images/mainpageimg/bpsman1.png'
import bpsm from '../images/mainpageimg/bpsm.png'
import bpsp from '../images/mainpageimg/bpsp.png'
import bpsmsg from '../images/mainpageimg/bpsmsg.png'
import sbsc1log1 from '../images/mainpageimg/sbsc1log1.png'
import sbsc1log2 from '../images/mainpageimg/sbsc1log2.png'
import sbsc1log3 from '../images/mainpageimg/sbsc1log3.png'
import '../Pricing/ranger.css'
import '../Pricing/pricing.css'



import Header2 from '../header/Header2';
import Footer from '../footer/footer';
import Headermb from '../header/Headermb';
import Discount from '../header/Discountoffer';
import $ from 'jquery';

import './land.css';

function Landing() {
  
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [nume, setNume] = useState('');

  const  handleSubmit = event => {
    event.preventDefault(); // 👈️ prevent page refresh
    var fname =document.getElementById('fname').value;
    var lname =document.getElementById('lname').value;
    var Email =document.getElementById('Email').value;
    var message =document.getElementById('message').value;
    var Phone =document.getElementById('Phone').value;
    var Company =document.getElementById('Company').value;
    var Nume =document.getElementById('Nume').value;
    var body = 'First Name: ' +fname + '<br/> Last Name: ' +lname + '<br/> Email: ' +"<a href='mailto:''+Email+''>"+Email+"</a>"+  '<br/> Message: ' +Phone+  '<br/> : ' +Phone+  '<br/> Company: ' +Company+  '<br/> Number: ' +Nume;
    
    if(fname === ""){
      alert("Please enter a first name to continue");
      $('#fname').focus();
      return false;
   }
   if(lname === ""){
     alert("Please enter a last name to continue");
     $('#lname').focus();
     return false;
  }
  if(Email === ""){
    alert("Please enter a email to continue");
    $('#Email').focus();
    return false;
  }
  if(Phone === ""){
    alert("Please enter a Contact Number to continue");
    $('#Phone').focus();
    return false;
  }
  if(Company === ""){
    alert("Please enter a company name to continue");
    $('#Company').focus();
    return false;
  }
  if(Nume === ""){
    alert("Please enter a number of employees to continue");
    $('#Nume').focus();
    return false;
  }
if(fname !== '' && lname !== '' && Email!== '') {

    window.Email.send({
      Host : "smtp.elasticemail.com",
      Username : "team@vibescommunications.com",
      Password : "82352C982B1BBCE0FB9B54B6430495575E34",
      To : 'team@vibescommunications.com',
      From : 'team@vibescommunications.com',
      Subject : "Message",
      Body : body
  }).then(
     alert("Message Sent"),
    setFirstName(''),
    setLastName(''),
    setEmail(''),
  setMessage(''),
  setPhone(''),
  setCompany(''),
  setNume(''),
  );

  }


 };

 const [productsData, setProductsData]=useState( products.filter(item=> item.category === 'd' && item.subcategory==='ab'));

  

 const isChecked1 =()=>{
  
   var slider = document.getElementById("myRange");
   
   if(document.getElementById("check__bx").checked && slider.value >='1' && slider.value <='15' ){
      const filteredProducts = products.filter(item=> item.
       category === 'a' && item.subcategory === 'ab')
      


       setProductsData(filteredProducts);
 
   }

 else if( slider.value >='1' && slider.value <='15' ){
     const filteredProducts = products.filter(item=> item.
      category === 'a' && item.subcategory === 'aba')
     


      setProductsData(filteredProducts);

  }

  else if(document.getElementById("check__bx").checked && slider.value>='16' && slider.value<='48'){
   const filteredProducts = products.filter(item=> item.
     category === 'b' && item.subcategory === 'ab')
     

 

     setProductsData(filteredProducts);

 }


   else if( slider.value>='16' && slider.value<='48'){
      const filteredProducts = products.filter(item=> item.
       category === 'b' && item.subcategory === 'aba')
      

       setProductsData(filteredProducts);
       
   }

   else if(document.getElementById("check__bx").checked && slider.value>='49' && slider.value<='79'){
     const filteredProducts = products.filter(item=> item.
       category === 'c' && item.subcategory === 'aba')
      

       setProductsData(filteredProducts);
 
   }


   else if(slider.value>='49' && slider.value<='79'){
     const filteredProducts = products.filter(item=> item.
       category === 'c' && item.subcategory === 'ab')
      

       setProductsData(filteredProducts);
 
   }


   else if(document.getElementById("check__bx").checked && slider.value>='80'  ){
     const filteredProducts = products.filter(item=> item.
       category === 'd' && item.subcategory === 'aba')
      

       setProductsData(filteredProducts);
 
   }

   else if(slider.value>='80'  ){


     const filteredProducts = products.filter(item=> item.
       category === 'd' && item.subcategory === 'ab')
      

       setProductsData(filteredProducts);
       
 
   }

   
 }




 useEffect(() => {
  var input = document.getElementById('myRange')
  input.value = 99

 
  

  function isOlderEdgeOrIE() {
    return (
      window.navigator.userAgent.indexOf("MSIE ") > -1 ||
      !!navigator.userAgent.match(/Trident.*rv\:11\./) ||
      window.navigator.userAgent.indexOf("Edge") > -1
    );
  }
  
  function valueTotalRatio(value, min, max) {
    return ((value - min) / (max - min)).toFixed(2);
  }
  
  function getLinearGradientCSS(ratio, leftColor, rightColor) {
    return [
      '-webkit-gradient(',
      'linear, ',
      'left top, ',
      'right top, ',
      'color-stop(' + ratio + ', ' + leftColor + '), ',
      'color-stop(' + ratio + ', ' + rightColor + ')',
      ')'
    ].join('');
  }
  
  function updateRangeEl(rangeEl) {
    var ratio = valueTotalRatio(rangeEl.value, rangeEl.min, rangeEl.max);
  
    rangeEl.style.backgroundImage = getLinearGradientCSS(ratio, '#104E71', '#c5c5c5');
  }
  
  function initRangeEl() {
    var rangeEl = document.querySelector('input[type=range]');
    var textEl = document.querySelector('input[type=text]');
  
    /**
     * IE/Older Edge FIX
     * On IE/Older Edge the height of the <input type="range" />
     * is the whole element as oposed to Chrome/Moz
     * where the height is applied to the track.
     *
     */
    if (isOlderEdgeOrIE()) {
      rangeEl.style.height = "20px";
      // IE 11/10 fires change instead of input
      // https://stackoverflow.com/a/50887531/3528132
      rangeEl.addEventListener("change", function(e) {
        textEl.value = e.target.value;
      });
      rangeEl.addEventListener("input", function(e) {
        textEl.value = e.target.value;
      });
    } else {
      updateRangeEl(rangeEl);
      rangeEl.addEventListener("input", function(e) {
        updateRangeEl(e.target);
        textEl.value = e.target.value;
      });
    }
  }
  
  initRangeEl();


  $(function() {
      var radios = $("#radios").radioslider();
      
  });

  (function(d, w, c) {
    w.ChatraID = 'td9DTmwopwxBzfMBw';
    var s = d.createElement('script');
    w[c] = w[c] || function() {
        (w[c].q = w[c].q || []).push(arguments);
    };
    s.async = true;
    s.src = 'https://call.chatra.io/chatra.js';
    if (d.head) d.head.appendChild(s);
})(document, window, 'Chatra');

  }, []);



  return (
    <div>
         <Helmet>
          <title>Landing | Vibes</title>
           <meta name="description" content="If you have questions about Vibes, contact us today and discover more about amazing and affordable office phone services & products from anywhere!"/>
   
     < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
     <meta charset="UTF-8"/>
     <link rel="stylesheet" href="style.css"/>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"/>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"/>
      <script src="https://code.jquery.com/jquery-3.4.1.js"></script>
           <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
          <script src="https://cdn.jsdelivr.net/npm/popper.js@1.12.9/dist/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
           <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous"/>
           <script src="https://smtpjs.com/v3/smtp.js">
</script>
       </Helmet>
       <Discount/>

       <Headermb/>
       <Header2/>

<section className='new_header_main'>
<div className='row_nlan'>
<div className='col_newa'>
      <h1>Pricing Page</h1>
      <h3>
Keep your team connected</h3>
<br />
<form onSubmit={handleSubmit}>
<div className='row_nlan' id='for'>
<div className='col_newa'>
    <input type="text" name="fname" id='fname' onChange={event => setFirstName(event.target.value)}     value={firstName}
 required placeholder='FIRST NAME'/>
</div>
<div className='col_newa'>

<input type="text"  name="lname" id='lname'    placeholder='LAST NAME'     onChange={event => setLastName(event.target.value)}   value={lastName}
 required />
</div>
</div>
<input type="text" className='for2' name="fname" id='fname' placeholder='FIRST NAME'/>
<input type="text" className='for2' name="lname" id='lname' placeholder='LAST NAME'/>

    <input type="text"  name="Email" id='Email'    placeholder='Email'     onChange={event => setEmail(event.target.value)}   value={email}
 required />

    <input type="text"  name="Phone" id='Phone'    placeholder='Phone'     onChange={event => setPhone(event.target.value)}   value={phone}
 required />
  

    <input type="text"  name="Company"  id='Company' placeholder='COMPANY'     onChange={event => setCompany(event.target.value)}   value={company}
 />
  

    <input type="text"  name="Nume"  id='Nume' placeholder='NUMBER OF EMPLOYEES'     onChange={event => setNume(event.target.value)}   value={nume}
 />
  
    <button  type="submit" >GET STARTED</button>
</form>
</div>
<div className='col_newa'>
<img src={vacll} alt="circle" />

</div>
</div>
</section>

<section>
<hr className='esline1'/>
<div className='sbscenter1'>
<p className='sbsc1txt1'>Our Connected Communications technology makes it simple to gain and retain customers.<br/>Get all your needs met with Vibes</p>
<p className='sbsc1txt1mb'>Our Connected Communications technology<br/> makes it simple to gain and retain customers.</p>
<p className='sbsc1txt1mb'>Get all your needs met with Vibes</p>

<img className='sbslineformid1' src={sbsline1formid1} alt='ecircle2'/> 
<img className='sbsc1log1' src={sbsc1log1} alt='ecircle2'/> 
<img className='sbscircle1c1' src={ecircle1} alt='ecircle2'/>
<p className='sbsc1txt2mb' >All Devices, One Experience</p>
<p className='sbsc1txt3mb' >Switch seamlessly between devices, <br /> maintaining smooth communication whether <br />you’re on your phone, tablet, or computer.</p>

<img className='sbslineformid1'style={{marginLeft:"30%"}} src={sbsline1formid1} alt='ecircle2'/> 
<img className='sbsbc1log1'  src={sbsc1log2} alt='ecircle2'/> 
<img className='sbsbcircle1c1' src={ecircle1} alt='ecircle2'/> 
<p className='sbsc1txt2mb' >Seamless Tech Integration</p>
<p className='sbsc1txt3mb' >Integrate effortlessly with your existing tools, <br /> creating a streamlined and efficient workspace<br /> for all your tasks.</p>



<img className='sbscc1log1'  src={sbsc1log3} alt='ecircle2'/> 
<img className='sbsccircle1c1'  src={ecircle1} alt='ecircle2'/> 
<p className='sbsc1txt2mb' >Direct Team Communication</p>
<p className='sbsc1txt3mb' >Enhance team dynamics with straightforward <br /> communication tools, ensuring you stay connected <br />and in sync with your colleagues.</p>

<p className='sbsc1txt2'style={{position:"absolute",marginLeft:"42.4%"}}>Seamless Tech Integration</p>

<p className='sbsc1txt2'style={{position:"absolute",marginLeft:"71.4%"}}>Direct Team Communication</p>
<p className='sbsc1txt2' > &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All Devices, One Experience</p>
<p className='sbsc1txt3' style={{position:"absolute",marginLeft:"24.4%",width:"50%",marginTop:"-.0%"}}>Integrate effortlessly with your existing tools, <br /> creating a streamlined and efficient workspace <br />for all your tasks.</p>
<p className='sbsc1txt3' style={{position:"absolute",marginLeft:"24.4%",width:"109%",marginTop:"-.0%"}}>Enhance team dynamics with straightforward <br />communication tools, ensuring you stay<br /> connected and in sync with your colleagues.</p>

<p className='sbsc1txt3' >Switch seamlessly between devices,<br /> maintaining smooth communication whether <br /> you’re on your phone, tablet, or computer.</p>

</div>

</section>
<hr className='esline1'/>

<section>
<div className='bpscenter'>
<img className='bpscircle2' src={bpscircle1} alt='bpscircle1'/> 
<img className='bpsskylogoc1' src={bpsskylogo3} alt='bpscircle1'/> 
<img className='bpsman1' src={bpsman1} alt='bpscircle1'/> 
<img className='bpsm'id='hid' src={bpsm} alt='bpscircle1'/> 
<img className='bpslineh' id='hid'src={bpslineh} alt='bpscircle1'/> 
<img className='bpsp' id='hid'src={bpsp} alt='bpscircle1'/> 
<img className='bpslineh2'id='hid' src={bpslineh} alt='bpscircle1'/> 
<img className='bpsmsg' id='hid' src={bpsmsg} alt='bpscircle1'/> 

<p className='bpstxtc1'>Features:</p>
<p className='bpstxtc2' id='hid2'>Unlimited Business-Grade Calling, Faxing, and Texting: Transitionbt <br /> smoothly from physical phone lines to Vibes, and enjoy limitless<br /> communication nationwide. Whether it’s calls, faxes, or texts, we’ve got<br /> you covered. </p>
<p className='bpstxtc2' id='hid2mb'>Unlimited Business-Grade Calling, Faxing, and Texting: <br /> Transitionbt  smoothly from physical phone lines to Vibes,<br /> and enjoy limitless communication nationwide. Whether <br />it’s calls, faxes, or texts, we’ve got you covered. </p>

<p className='bpstxtc2'id='hid2'>Mobile-Optimized with Team Collaboration: Stay connected no matter where<br /> you are. Our VoIP phone service ensures you never miss an important call,<br /> and our team collaboration tools keep everyone in sync.

</p>
<p className='bpstxtc2'id='hid2mb'>Mobile-Optimized with Team Collaboration: Stay connected<br /> no matter where you are. Our VoIP phone service ensures <br />you never miss an important call, and our team collaboration<br /> tools keep everyone in sync.

</p>
<p className='bpstxtc2' id='hid2'>Enhanced Voicemail and Call Management: Experience convenience with<br /> voicemail transcriptions sent directly to your inbox and utilize advanced<br /> call management tools to make every interaction count.

</p>
<p className='bpstxtc2' id='hid2mb'>Enhanced Voicemail and Call Management: Experience <br />convenience with voicemail transcriptions sent directly<br /> to your inbox and utilize advanced call management <br /> tools to make every interaction count.

</p>
</div>
</section>
<hr className='esline1'/>
<section>
<br /><br /><br /><br /><br />

<section id="priceflex" style={{justifyContent:"space-between",width:"82%",margin:"auto",alignItems:"center"}}>

<div>
<div className="slide__container">
  <input type="range" min="10" max="99"  onChange={isChecked1}  className="slider__1" id="myRange"/>
  <p><span id="size__text" >1-4</span><span id="size__text" style={{marginLeft:"20%"}}>5-19</span><span id="size__text" style={{marginLeft:"20%"}}>20-99</span> <span id="size__text" style={{marginLeft:"20%"}} >100+</span></p>
</div>
     </div>
       
       <div className='mn'>
            <h2 >MONTHLY</h2>
    &nbsp;&nbsp;&nbsp; 

    <label className="switch"  >
  
  <input type="checkbox"  id='check__bx' onChange={isChecked1} />
 
  <span className="slider round"></span>
</label>

&nbsp;&nbsp;&nbsp; 

<h2 >ANNUALLY</h2>
</div>

</section>


<section className='pt-0'>
      <Container>
        <Row>
          
            {productsData.length === 0 ? ( <h1 className='text-center fs-4'> No Products  are found!</h1>
            ):(
                <ProductsList data={productsData}/>
            )}

            
        </Row>
      </Container>
    </section>






<div className='escen3bx2' style={{marginTop:"180px"}}>
  
<p className='escen3bx2tx1pm' style={{fontSize:"1vw"}}><br></br><br></br> Embrace the future of communication with our VoIP phone service, designed for the <br /> dynamic nature of modern work. Seamlessly transition between devices and maintain your connectivity on the go, <br /> ensuring you're always in the loop and ready for action.

</p>
<p className='escen3bx2tx1pmmb' style={{fontSize:"8px"}} >Embrace the future of communication with our VoIP phone service, designed for <br /> the dynamic nature of modern work. Seamlessly transition between devices and maintain your connectivity on the go,<br /> ensuring you're always in the loop and ready for action.

</p>


<img className='eslastv' src={eslastv} alt='ecircle2'/> 
<img className='esfem3' src={esfem3} alt='ecircle2'/> 

</div>  
</section>
<section>
<div className='bpsclast'>
       <div className='bpslastpart'>
    <p className='bpslasptxt1'>Salient Features for Enhanced Communication:</p>
    <div className='bpslastpcircle1'>
    <img className='bpssecure' src={bpssecure} alt='bpscircle1'/> 
    <p  className='bpssecuret1'>Safe & Secure</p>
    <p style={{color:"#104E71"}}id='tls'>We prioritize your communication security, ensuring all calls, texts, and faxes remain confidential and protected.

</p>
    </div>
    <div className='bpslastpcircle1'>
    <img className='bpssecure' src={bpsservice} alt='bpscircle1'/> 
    <p  className='bpssecuret1'>100% Uptime SLA</p>
    <p style={{color:"#104E71"}}id='tls'>Rely on us for consistent service. Our 100% uptime guarantee ensures we're always available when you need us.</p>
    </div>
    <div className='bpslastpcircle1'>
    <img className='bpssecure' src={bpssupport} alt='bpscircle1'/> 
    <p  className='bpssecuret1'>24/7 SUPPORT</p>
    <p style={{color:"#104E71"}} id='tls'>Our dedicated support team is here 24/7, ready to assist and help you maximize your use of our services.</p>
    </div>
   </div>
   
</div>

</section>

<div className='mg_land'>
  s
</div>
<Footer/>
</div>

  );
}

export default Landing;
