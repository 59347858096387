import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

import '../mainPage/main.css';

import '../mainPage/dropdown.css';
import './resources.css'

import Header from '../header/Header'
import { useEffect } from 'react';
import {Helmet} from"react-helmet";
import Pdf from './hosted.pdf';
import why from './why.pdf';

import logodarkblue from '../images/mainpageimg/logodarkblue.png'
import bpscircle1 from '../images/mainpageimg/bpscircle1.png'
import bpsgirl1 from '../images/mainpageimg/whywebgirl1.png'
import hostedpdf from './w2.jpg'
import why1 from './w1.jpg'
import Footer from '../footer/footer';
import eslastv from '../images/esolutiions/eslastv.png'
import esfem3 from '../images/esolutiions/esfem3.png'
import Headermb from '../header/Headermb';
import Contact2 from '../contact/contact2';
import Discount from '../header/Discountoffer';
import Contact3 from '../contact/contact3';

function Resources() {
  

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
   useEffect(() => {   
    (function(d, w, c) {
      w.ChatraID = 'td9DTmwopwxBzfMBw';
      var s = d.createElement('script');
      w[c] = w[c] || function() {
          (w[c].q = w[c].q || []).push(arguments);
      };
      s.async = true;
      s.src = 'https://call.chatra.io/chatra.js';
      if (d.head) d.head.appendChild(s);
  })(document, window, 'Chatra');

    }, []);


  return (
    <div>
        <Discount/>

         <Helmet>
          <title>Why vibes for Cloud Communications | Why We're the Best | Vibes</title>
          <meta name="description" content="Vibes is best in class Communications Cloud that connects employees, customers, and applications to make people more productive."/>

          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"/>
          < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
     <meta charset="UTF-8"/>
           <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous"/>

  
       </Helmet>
     
       <div>
  <Discount/>
  <Headermb/>
    <Header/>
    <div className='main'> 

    <div className="dropdown">
  <div className="nbartext2" style={{border:"none",backgroundColor:"white"}}>PRODUCTS&nbsp;&nbsp;
        <i className="fa fa-caret-down"></i>
  </div>
  <div className="dropdown-contentb" style={{marginLeft:"7%",marginTop:"3.38%"}}>

  <a href="/voip-phone" style={{color:"#104E71",fontWeight:"400"}}>VOIP PHONE</a>
  <a href="/business-phone-system" style={{color:"#104E71",fontWeight:"400"}}>BUSINESS PHONE SYSTEM</a>
  <a href="/team-chat-system" style={{color:"#104E71",fontWeight:"400"}}>TEAM CHAT SYSTEM</a>
  </div>
</div>
   <div className="dropdown">
  <div className="nbartext" style={{border:"none",backgroundColor:"white"}}>SOLUTIONS&nbsp;&nbsp;
        <i className="fa fa-caret-down"></i>
  </div>
  <div className="dropdown-content">
  <h4   style={{color:"#104E71",fontWeight:"500",marginLeft:"5%",marginTop:"8%",fontFamily:"Oswald",fontSize:"1.4vw"}}>BY AUDIENCE</h4>
  <a href="/solutions" style={{color:"#104E71",fontWeight:"400"}}>CORPORATIONS	</a>
  <a href="/small-business-solution" style={{color:"#104E71",fontWeight:"400"}}>SMALL BUSINESSES </a>
  <a href="/startup" style={{color:"#104E71",fontWeight:"400"}}>STARTUPS</a>
  </div>
</div>

<a href='/prices' className='nbartext'>PRICING</a>
<div className="dropdown">
<a href='/whyvibes' className="nbartext" style={{border:"none",backgroundColor:"white"}}>WHY VIBES &nbsp;
        <i className="fa fa-caret-down"></i>
  </a>
  <div className="dropdown-content" style={{marginLeft:"37%",marginTop:"3.38%"}}>
  <a href='/blogs' style={{color:"#104E71",fontWeight:"400",marginTop:"10%"}}>BLOG</a>
  </div>
</div>
      <a href='/contact' className='nbartext'>CONTACT US</a>


    </div>
</div>
<div className='clearfloat'>
</div>
<div className='bpsmain'>
<br/>
<br/>

<h1 className='resh2'>Vibes Communications</h1>
<h3 className='resh1'>Guides & Tutorials</h3>

 
<hr className='line1wvb'/>
</div>

<section className='pdffile'>
  <h2>Vibes Voice & Analytics</h2>
  <div className='rowpdf'>

  <div className='colpdf' style={{textAlign:"center"}}><a  href = {Pdf} target = "_blank"><img  style={{width:"100%",height:"25vh"}} src={hostedpdf} alt='ecircle2'/></a>
  <a  style={{textAlign:"center"}} href = {Pdf} target = "_blank">Hosted PBX & VOIP</a>
</div>
   <div className='colpdf' style={{textAlign:"center"}}><a href = {why} target = "_blank"><img  style={{width:"100%",height:"25vh"}} src={why1} alt='ecircle2'/></a>
   <a  style={{textAlign:"center"}} href = {Pdf} target = "_blank">Future-proof your business</a>
</div>
  </div>

</section>


<div className='escen3bx2' style={{marginTop:"12%"}}>
<p className='escen3bx2tx1'>Do less work, get better communications </p>
<p className='escen3bx2tx1mb'>Do less work, get<br/> better communications </p>
<div className='escen3btnbx' style={{cursor:"pointer"}} data-toggle="modal" data-target="#myModal">CONTACT US NOW</div>
<div class="modal fade" id="myModal">
    <div className="modal-dialog modal-xl" >
      <div class="modal-content" >
      <Contact3/> 
      </div>
    </div>
  </div><img className='eslastv' src={eslastv} alt='ecircle2'/> 
<img className='esfem3' src={esfem3} alt='ecircle2'/> 

</div>  
<div className='clearfloat'/>
<div id="fb-root"></div>

<div id="fb-customer-chat" class="fb-customerchat">
</div>
<Footer/>
</div>

  );
}

export default Resources;