import React from 'react';
import products from '../../assests/data/product'
import ProductsList from './ProductList';
import {Container,Row,Col} from "reactstrap"
import '../mainPage/main.css';
import '../mainPage/dropdown.css';
import '../esolution/esolution.css';
import '../esolution/escenter2.css';
import '../bphonesystem/center3.css'
import './ranger.css'
import './pricing.css'
import Header from '../header/Header'
import { useEffect,useState } from 'react';
import {Helmet} from"react-helmet";
import logodarkblue from '../images/mainpageimg/logodarkblue.png'
import skylogo3 from '../images/mainpageimg/skylogo3.png'
import ecircle1 from '../images/esolutiions/ecircle1.png'
import esmale1 from '../images/productmessaging/pmfem1.png'
import eslastv from '../images/esolutiions/eslastv.png'
import esfem3 from '../images/esolutiions/esfem3.png'

import Footer from '../footer/footer';
import Headermb from '../header/Headermb';
import Contact2 from '../contact/contact2';
import Discount from '../header/Discountoffer';
import $ from 'jquery';
import Contact3 from '../contact/contact3';

function Pricing() {
  

  const [productsData, setProductsData]=useState( products.filter(item=> item.category === 'd' && item.subcategory==='ab'));

  

    const isChecked1 =()=>{
     
      var slider = document.getElementById("myRange");
      
      if(document.getElementById("check__bx").checked && slider.value >='1' && slider.value <='15' ){
         const filteredProducts = products.filter(item=> item.
          category === 'a' && item.subcategory === 'ab')
         
  

          setProductsData(filteredProducts);
    
      }

    else if( slider.value >='1' && slider.value <='15' ){
        const filteredProducts = products.filter(item=> item.
         category === 'a' && item.subcategory === 'aba')
        
 

         setProductsData(filteredProducts);
   
     }

     else if(document.getElementById("check__bx").checked && slider.value>='16' && slider.value<='48'){
      const filteredProducts = products.filter(item=> item.
        category === 'b' && item.subcategory === 'ab')
        

    

        setProductsData(filteredProducts);
  
    }


      else if( slider.value>='16' && slider.value<='48'){
         const filteredProducts = products.filter(item=> item.
          category === 'b' && item.subcategory === 'aba')
         

          setProductsData(filteredProducts);
          
      }

      else if(document.getElementById("check__bx").checked && slider.value>='49' && slider.value<='79'){
        const filteredProducts = products.filter(item=> item.
          category === 'c' && item.subcategory === 'aba')
         

          setProductsData(filteredProducts);
    
      }


      else if(slider.value>='49' && slider.value<='79'){
        const filteredProducts = products.filter(item=> item.
          category === 'c' && item.subcategory === 'ab')
         

          setProductsData(filteredProducts);
    
      }


      else if(document.getElementById("check__bx").checked && slider.value>='80'  ){
        const filteredProducts = products.filter(item=> item.
          category === 'd' && item.subcategory === 'aba')
         

          setProductsData(filteredProducts);
    
      }

      else if(slider.value>='80'  ){


        const filteredProducts = products.filter(item=> item.
          category === 'd' && item.subcategory === 'ab')
         

          setProductsData(filteredProducts);
          
    
      }

      
    }


    


    
   useEffect(() => {
    var input = document.getElementById('myRange')
    input.value = 99
  
   
    

    function isOlderEdgeOrIE() {
      return (
        window.navigator.userAgent.indexOf("MSIE ") > -1 ||
        !!navigator.userAgent.match(/Trident.*rv\:11\./) ||
        window.navigator.userAgent.indexOf("Edge") > -1
      );
    }
    
    function valueTotalRatio(value, min, max) {
      return ((value - min) / (max - min)).toFixed(2);
    }
    
    function getLinearGradientCSS(ratio, leftColor, rightColor) {
      return [
        '-webkit-gradient(',
        'linear, ',
        'left top, ',
        'right top, ',
        'color-stop(' + ratio + ', ' + leftColor + '), ',
        'color-stop(' + ratio + ', ' + rightColor + ')',
        ')'
      ].join('');
    }
    
    function updateRangeEl(rangeEl) {
      var ratio = valueTotalRatio(rangeEl.value, rangeEl.min, rangeEl.max);
    
      rangeEl.style.backgroundImage = getLinearGradientCSS(ratio, '#104E71', '#c5c5c5');
    }
    
    function initRangeEl() {
      var rangeEl = document.querySelector('input[type=range]');
      var textEl = document.querySelector('input[type=text]');
    
      /**
       * IE/Older Edge FIX
       * On IE/Older Edge the height of the <input type="range" />
       * is the whole element as oposed to Chrome/Moz
       * where the height is applied to the track.
       *
       */
      if (isOlderEdgeOrIE()) {
        rangeEl.style.height = "20px";
        // IE 11/10 fires change instead of input
        // https://stackoverflow.com/a/50887531/3528132
        rangeEl.addEventListener("change", function(e) {
          textEl.value = e.target.value;
        });
        rangeEl.addEventListener("input", function(e) {
          textEl.value = e.target.value;
        });
      } else {
        updateRangeEl(rangeEl);
        rangeEl.addEventListener("input", function(e) {
          updateRangeEl(e.target);
          textEl.value = e.target.value;
        });
      }
    }
    
    initRangeEl();
  

    $(function() {
        var radios = $("#radios").radioslider();
        
    });

    (function(d, w, c) {
      w.ChatraID = 'td9DTmwopwxBzfMBw';
      var s = d.createElement('script');
      w[c] = w[c] || function() {
          (w[c].q = w[c].q || []).push(arguments);
      };
      s.async = true;
      s.src = 'https://call.chatra.io/chatra.js';
      if (d.head) d.head.appendChild(s);
  })(document, window, 'Chatra');

    }, []);


  return (
    <div>
         <Helmet>
          <title>Business SMS & MMS messaging | Team Chat | Vibes</title>
          <meta name="description" content="Boost your business performance with SMS & MMS text messages from your device or computer with Vibes. Learn how you can speed up your texting workflow, Team Chat, and more."/>
          <link rel="stylesheet" href="path/to/radioslider.min.css"/>
          <script src="path/to/jquery.min.js"></script>
<script src="path/to/jquery.radioslider.min.js"></script>

   
       </Helmet>
     
       <div>
  <Discount/>
  <Headermb/>
    <Header/>
    <div className='main'> 

    <div className="dropdown">
  <div className="nbartext2" style={{border:"none",backgroundColor:"white"}}>PRODUCTS&nbsp;&nbsp;
        <i className="fa fa-caret-down"></i>
  </div>
  <div className="dropdown-contentb" style={{marginLeft:"7%",marginTop:"3.38%"}}>

  <a href="/voip-phone" style={{color:"#104E71",fontWeight:"400"}}>VOIP PHONE</a>
  <a href="/business-phone-system" style={{color:"#104E71",fontWeight:"400"}}>BUSINESS PHONE SYSTEM</a>
  <a href="/team-chat-system" style={{color:"#104E71",fontWeight:"400"}}>TEAM CHAT SYSTEM</a>
  </div>
</div>
   <div className="dropdown">
  <div className="nbartext" style={{border:"none",backgroundColor:"white"}}>SOLUTIONS&nbsp;&nbsp;
        <i className="fa fa-caret-down"></i>
  </div>
  <div className="dropdown-content">
  <h4   style={{color:"#104E71",fontWeight:"500",marginLeft:"5%",marginTop:"8%",fontFamily:"Oswald",fontSize:"1.4vw"}}>BY AUDIENCE</h4>
  <a href="/solutions" style={{color:"#104E71",fontWeight:"400"}}>CORPORATIONS	</a>
  <a href="/small-business-solution" style={{color:"#104E71",fontWeight:"400"}}>SMALL BUSINESSES </a>
  <a href="/startup" style={{color:"#104E71",fontWeight:"400"}}>STARTUPS</a>
  </div>
</div>

<a href='/prices' className='nbartext'>PRICING</a>
<div className="dropdown">
<a href='/whyvibes' className="nbartext" style={{border:"none",backgroundColor:"white"}}>WHY VIBES &nbsp;
        <i className="fa fa-caret-down"></i>
  </a>
  <div className="dropdown-content" style={{marginLeft:"37%",marginTop:"3.38%"}}>
  <a href='/blogs' style={{color:"#104E71",fontWeight:"400",marginTop:"10%"}}>BLOG</a>
  <a href='/resources' style={{color:"#104E71",fontWeight:"400",marginTop:"10%"}}>RESOURCES</a>
  </div>
</div>
      <a href='/contact' className='nbartext'>CONTACT US</a>


    </div>
</div>
<div className='clearfloat'>
</div>
<div className='esmain'>
<img className='ecircle1' src={ecircle1} alt='ecircle1'/> 
<img className='esmale1' src={esmale1} alt='esmale1'/> 
<h1 className='esmaintx1' style={{fontSize:"1.2vw",marginTop:"6%",visibility:"hidden"}}>Plans and Pricing</h1>
<h1 className='esmaintx1' style={{fontSize:"4.2vw",marginTop:"6%"}}>Plans and Pricing</h1>
<p className='esmaintx4' style={{}}>Choose annual and save!</p>
<p className='esmaintx4mb' style={{}} >Choose annual and save!</p>
</div>
<br/>


<hr className='esline1'/>


<br/>
<section id="priceflex" style={{justifyContent:"space-between",width:"82%",margin:"auto",alignItems:"center"}}>

<div>
<div className="slide__container">
  <input type="range" min="10" max="99"  onChange={isChecked1}  className="slider__1" id="myRange"/>
  <p><span id="size__text" >1-4</span><span id="size__text" style={{marginLeft:"20%"}}>5-19</span><span id="size__text" style={{marginLeft:"20%"}}>20-99</span> <span id="size__text" style={{marginLeft:"20%"}} >100+</span></p>
</div>
     </div>
       
       <div className='mn'>
            <h2 >MONTHLY</h2>
    &nbsp;&nbsp;&nbsp; 

    <label className="switch"  >
  
  <input type="checkbox"  id='check__bx' onChange={isChecked1} />
 
  <span className="slider round"></span>
</label>

&nbsp;&nbsp;&nbsp; 

<h2 >ANNUALLY</h2>
</div>

</section>


<section className='pt-0'>
      <Container>
        <Row>
          
            {productsData.length === 0 ? ( <h1 className='text-center fs-4'> No Products  are found!</h1>
            ):(
                <ProductsList data={productsData}/>
            )}

            
        </Row>
      </Container>
    </section>






<div className='escen3bx2' style={{marginTop:"240px"}}>
<p className='escen3bx2tx1pm' >Messaging and collaboration done right. Keep your team chat and messaging<br/>
streamlined and organized, so you can focus on the conversations that matter.</p>
<p className='escen3bx2tx1pmmb' >Messaging and collaboration done <br/>right. Keep your team chat and<br/> messaging
streamlined and<br/> organized, so you can focus on the<br/> conversations that matter.</p>

<div className='escen3btnbx' style={{cursor:"pointer"}} data-toggle="modal" data-target="#myModal">CONTACT US NOW</div>
<div class="modal fade" id="myModal">
    <div className="modal-dialog modal-xl" >
      <div class="modal-content" >
      <Contact3/> 
      </div>
    </div>
  </div>
<img className='eslastv' src={eslastv} alt='ecircle2'/> 
<img className='esfem3' src={esfem3} alt='ecircle2'/> 

</div>  
<div id="fb-root"></div>

<div id="fb-customer-chat" class="fb-customerchat">
</div>

<Footer/>
</div>

  );
}

export default Pricing;
