import '../mainPage/main.css';


import '../mainPage/dropdown.css';
import './voip.css'

import React from 'react';
import Header from '../header/Header'
import { useEffect } from 'react';
import {Helmet} from"react-helmet";
import logodarkblue from '../images/mainpageimg/logodarkblue.png'
import bpscircle1 from '../images/mainpageimg/bpscircle1.png'
import bpsgirl1 from '../images/mainpageimg/whywebgirl1.png'
import skylogo3 from '../images/mainpageimg/skylogo3.png'
import voipphone2 from '../images/mainpageimg/voipphone2.png'
import Footer from '../footer/footer';
import Headermb from '../header/Headermb';
import {motion} from "framer-motion"
import Contact2 from '../contact/contact2';
import Discount from '../header/Discountoffer';
import Contact3 from '../contact/contact3';
import ipconf from '../images/pricephones/ipconf.png';
import c7811 from '../images/pricephones/c7811.png';
import c7821 from '../images/pricephones/c7821.png';
import c6851 from '../images/pricephones/c8861.png'
import c7841 from '../images/pricephones/c7841.png'
import c7861 from '../images/pricephones/c7861.png'
import c8811 from '../images/pricephones/c8811.png'
import c8841 from '../images/pricephones/c8841.png'
import c8851 from '../images/pricephones/c8851.png'
import c8861 from '../images/pricephones/c8861.png'
import poe2 from '../images/pricephones/poe2.png'
import t46u from '../images/pricephones/t46u.png'
import t48u from '../images/pricephones/t48ua.png'
import dcphone from '../images/pricephones/dcphone.png'
import lcd4 from '../images/pricephones/poe2.png'
import t40g from '../images/pricephones/t40g.png'
import t41s from '../images/pricephones/t41s.png'
import t42s from '../images/pricephones/t42s.png'
import t43u from '../images/pricephones/t43u.png'
import t58w from '../images/pricephones/t58w.png'
import t53 from '../images/pricephones/t53.png'
import t53w from '../images/pricephones/t53w.png'
import t57w from '../images/pricephones/t57w.png'
import t54w from '../images/pricephones/t54w.png'
import tip from '../images/pricephones/tip.png'
import y1 from '../images/pricephones/y1.png'
import yu from '../images/pricephones/yu.png'
import y59g from '../images/pricephones/y59g.png'

function Voip() {
  
   useEffect(() => {   
    (function(d, w, c) {
      w.ChatraID = 'td9DTmwopwxBzfMBw';
      var s = d.createElement('script');
      w[c] = w[c] || function() {
          (w[c].q = w[c].q || []).push(arguments);
      };
      s.async = true;
      s.src = 'https://call.chatra.io/chatra.js';
      if (d.head) d.head.appendChild(s);
  })(document, window, 'Chatra');

    }, []);


  return (
    <div>
        <Discount/>

         <Helmet>
          <title>Voip Desk Phone Devices & Headsets Online in USA - Vibes</title>
          <meta name="description" content="Choose from a wide selection of VoIP phones with Vibes Find a variety of IP devices desk phones, headsets, and conference phones at reasonable prices."/>

          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous"></link>
          <meta name="description" content="Vibes is best in class Communications Cloud that connects employees, customers, and applications to make people more productive."/>
          <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.12.9/dist/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
  
       </Helmet>
     
       <div>
  <Discount/>
  <Headermb/>
    <Header/>
    <div className='main'> 

    <div className="dropdown">
  <div className="nbartext2" style={{border:"none",backgroundColor:"white"}}>PRODUCTS&nbsp;&nbsp;
        <i className="fa fa-caret-down"></i>
  </div>
  <div className="dropdown-contentb" style={{marginLeft:"7%",marginTop:"3.38%"}}>

  <a href="/voip-phone" style={{color:"#104E71",fontWeight:"400"}}>VOIP PHONE</a>
  <a href="/business-phone-system" style={{color:"#104E71",fontWeight:"400"}}>BUSINESS PHONE SYSTEM</a>
  <a href="/team-chat-system" style={{color:"#104E71",fontWeight:"400"}}>TEAM CHAT SYSTEM</a>
  </div>
</div>
   <div className="dropdown">
  <div className="nbartext" style={{border:"none",backgroundColor:"white"}}>SOLUTIONS&nbsp;&nbsp;
        <i className="fa fa-caret-down"></i>
  </div>
  <div className="dropdown-content">
  <h4   style={{color:"#104E71",fontWeight:"500",marginLeft:"5%",marginTop:"8%",fontFamily:"Oswald",fontSize:"1.4vw"}}>BY AUDIENCE</h4>
  <a href="/solutions" style={{color:"#104E71",fontWeight:"400"}}>CORPORATIONS	</a>
  <a href="/small-business-solution" style={{color:"#104E71",fontWeight:"400"}}>SMALL BUSINESSES </a>
  <a href="/startup" style={{color:"#104E71",fontWeight:"400"}}>STARTUPS</a>
  </div>
</div>

<a href='/prices' className='nbartext'>PRICING</a>
<div className="dropdown">
<a href='/whyvibes' className="nbartext" style={{border:"none",backgroundColor:"white"}}>WHY VIBES &nbsp;
        <i className="fa fa-caret-down"></i>
  </a>
  <div className="dropdown-content" style={{marginLeft:"37%",marginTop:"3.38%"}}>
  <a href='/blogs' style={{color:"#104E71",fontWeight:"400",marginTop:"10%"}}>BLOG</a>
  </div>
</div>
      <a href='/contact' className='nbartext'>CONTACT US</a>


    </div>
</div>
<div className='clearfloat'>
</div>
<div className='bpsmain'>
<img className='bpscircle1' src={bpscircle1} alt='bpscircle1'/> 
<img className='bpsgirl1' src={bpsgirl1} alt='bpscircle1'/> 
<h1 className='vtext1'>VoIP Desk Phones</h1>
<h2 className='vtext2'>Vibes provides team chat and messaging app for the modern workplace. With features like file<br/> sharing and collaboration, it's easy to stay organized and get work done.</h2>
<hr className='linevphone'/>
<h2 className='vtext3'>VOIP DESK PHONES</h2>
<hr className='linevphone'/>

</div>
  
  <section className='voip__phones'>
    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Yealink CP960 Conference Phone</h2>
          <h3>Transform your conference calls with the Yealink CP96</h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={y1} alt='circle2'/> 
          
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$21.95-Per Month</h3>
          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong">See Details</motion.button>



<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={y1} alt='circle2'/>
      <div className='modal__list'>
      <h2>Yealink CP960 Conference Phone</h2>
      <h3>SKU: YEA-CP960</h3>
      <ul>
      <li>Optimal HD audio, full duplex technology</li>
      <li>Yealink Noise Proof Technology</li>
      <li>20-foot (6-meter) and 360-degree microphone pickup range</li>
      <li>Two additional CP Wireless Expansion Mic CPW90</li>
      <li>Built-in 3-microphone array</li>
      <li>Based on Android 5.1 operating system</li>
      <li>5-inch multi-touch screen with 720x1280 resolution</li>
      <li>Yealink Pentagon Meeting Room</li>
      <li>5-way conference call</li>
      <li>Hybrid UC Meeting</li>
      <li>Built-in Wi-Fi (2.4GHz/5GHz, 802.11.b/g/n/ac)</li>
      <li>Built-in Bluetooth 4.0</li>
      <li>Connect to PC via USB Micro-B port</li>
      <li>Power Supply not included</li>
      
      <h3>  $21.95 
  per unit, per month</h3>
      </ul>
        </div> 
      <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Yealink SIP-T53 Prime Business Phone</h2>
<h3>Experience enhanced productivity with the Yealink SIP-T53 Prime Business Phone</h3>
</div>
<div className='voip__bx__flexb'>

<img  src={t53} alt='circle2'/> 

<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$6.95-Per Month</h3>

 <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong2">See Details</motion.button>



<div class="modal fade" id="exampleModalLong2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={t53} alt='circle2'/>
<div className='modal__list'>
<h2>Yealink SIP-T53 Prime Business Phone</h2>
<h3>SKU: YEA-SIP-T53</h3>
<ul>
<li>Entry-level phone, well suited for common workspace</li>
<li>3.7" 360x160-pixel graphical display, fully adjustable</li>
<li>HD Voice with Yealink Acoustic Shield technology</li>
<li>8 line keys, 12 SIP accounts, 21 memory keys</li>
<li>Bluetooth (YEA-BT41 required) and Wi-Fi(YEA-WF40 or YEA-WF50 required)</li>
<li>Dual-port Gigabit Ethernet</li>
<li>1xUSB port for call recording and wireless USB headset</li>
<li>YEA-CP930W can be connected to the phones with the YEA-DD10K dect dongle</li>
<li>Power supply not included</li>
<h3> $6.95 
 per unit, per month</h3>
</ul>

</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>

    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Yealink SIP-T53W Prime Business Phone</h2>
          <h3>Stay connected on-the-go with the wireless Yealink SIP-T53W Prime Business Phone</h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={t53w} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$6.95-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong3">See Details</motion.button>



<div class="modal fade" id="exampleModalLong3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={t53w} alt='circle2'/>
      <div className='modal__list'>
      <h2>Yealink SIP-T53W Prime Business Phone</h2>
      <h3>SKU: YEA-SIP-T53W</h3>
      <ul>
      <li>Entry-level phone, well suited for common workspace</li>
      <li>3.7" 360x160-pixel graphical display, fully adjustable</li>
      <li>HD Voice with Yealink Acoustic Shield technology</li>
      <li>8 line keys, 12 SIP accounts, 21 memory keys</li>
      <li>Built-in Bluetooth, Wi-Fi</li>
      <li>Dual-port Gigabit Ethernet</li>
      <li>1xUSB port for call recording and wireless USB headset</li>
      <li>YEA-CP930W can be connected to the phones with the YEA-DD10K dect dongle</li>
      <li>Power supply not included</li>
      <h3> $6.95 
 per unit, per month</h3>
     
      </ul>
        </div> 
        <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Yealink SIP-T57W Prime Business Phone
</h2>
<h3>Enjoy a smarter and more productive workspace with the Yealink SIP-T57W Prime Business Phone
</h3>
</div>
<div className='voip__bx__flexb'>
<img  src={t57w} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$10.95-Per Month</h3>

<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong4">See Details</motion.button>



<div class="modal fade" id="exampleModalLong4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={t57w} alt='circle2'/>
<div className='modal__list'>
<h2>Yealink SIP-T57W Prime Business Phone
</h2>
<h3>SKU: YEA-SIP-T54W</h3>
<ul>
<li>7” 800 x 480 capacitive adjustable touchscreen</li>
<li>Adjustable LCD screen</li>
<li>Built-in Bluetooth 4.2</li>
<li>Built-in dual band 2.4G/5G Wi-Fi (802.11a/b/g/n/ac)</li>
<li>USB 2.0 port for USB recording, wired/wireless USB headsets and EXP50</li>
<li>Up to 16 VoIP accounts</li>
<li>Dual-port Gigabit Ethernet</li>
<li>PoE support</li>
<li>HAC Handset</li>
<li>Paperless label design</li>
<li>Wall mountable</li>
<li>*Incompatible with the T57W</li>
<h3> $10.95 
 per unit, per month
</h3>
</ul>

</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>
    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Yealink SIP-T54W Prime Business Phone</h2>
          <h3>Elevate your communication with the Yealink SIP-T54W Prime Business Phone
</h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={t54w} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$8.95-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong5">See Details</motion.button>



<div class="modal fade" id="exampleModalLong5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={t54w} alt='circle2'/>
      <div className='modal__list'>
      <h2>Yealink SIP-T54W Prime Business Phone</h2>
      <h3>SKU: YEA-SIP-T57W</h3>
      <ul>
      <li>Mid-level phone, ideal for professionals and managers with moderate call load</li>
      <li>4.3" 480x272-pixel color display, fully adjustable</li>
      <li>POE Available: Yes</li>
      <li>HD Voice with Yealink Acoustic Shield technology</li>
      <li>10 line keys, 16 SIP accounts, 27 memory keys</li>
      <li>Built-in Bluetooth, Wi-Fi</li>
      <li>Dual-port Gigabit Ethernet</li>
      <li>1xUSB port for call recording and wireless USB headset</li>
      <li>YEA-CP930W can be connected to the phones with the YEA-DD10K dect dongle</li>
      <li>Warranty with Purchase: 3 years</li>
      <li>Power supply not included</li>
      <h3> $8.95 
 per unit, per month</h3>
    
      </ul>
       </div>
       
       <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Yealink Touch-sensitive HD IP Conference Phone</h2>
<h3>Experience crystal clear audio with the Yealink Touch-sensitive HD IP Conference Phone
</h3>
</div>
<div className='voip__bx__flexb'>

<img  src={tip} alt='circle2'/> 

<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$14.95-Per Month</h3>

<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong6">See Details</motion.button>

<div class="modal fade" id="exampleModalLong6" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={tip} alt='circle2'/>
<div className='modal__list'>
<h2>Yealink Touch-sensitive HD IP Conference Phone</h2>
<h3>SKU: YEA-CP920</h3>
<ul>
<li>Optimal HD audio, full duplex technology</li>
<li>Yealink Noise Proof Technology</li>
<li>20-foot (6-meter) and 360-degree voice pickup</li>
<li>Built-in 3-microphone array</li>
<li>Sensitive touch keypad</li>
<li>3.1” 248x120-pixel graphical LCD with backlight</li>
<li>Power over Ethernet</li>
<li>Includes Power Supply</li>
<li>5-way conference call</li>
<li>Hybrid UC Meeting</li>
<li>Built-in Wi-Fi (2.4GHz, 802.11.b/g/n)</li>
<li>Built-in Bluetooth 4.0</li>
<li>Local USB call recording</li>
<h3> $14.95 
 per unit, per month</h3>
</ul>

</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>

    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Yealink Ultra-elegant Gigabit IP Phone 
</h2>
          <h3>Stay connected in style with the Yealink Ultra-elegant Gigabit IP Phone
 </h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={yu} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$7.95-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong7">See Details</motion.button>



<div class="modal fade" id="exampleModalLong7" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={yu} alt='circle2'/>
      <div className='modal__list'>
      <h2>Yealink Ultra-elegant Gigabit IP Phone </h2>
      <h3>YEA-SIP-T46S</h3>
      <ul>
      <li>Yealink Optima HD voice</li>
      <li>Codecs: Opus, G.722, G.711(A/u), G.723, G.729AB, G.726, iLBC</li>
      <li>Dual-port Gigabit Ethernet</li>
      <li>Display: 4.3" 480 x 272 pixel color with backlight</li>
      <li>Dual-color LEDs</li>
      <li>4 Soft Keys</li>
      <li>10 Line keys can be programmed with up to 27 features on 3-page view</li>
      <li>A built-in USB port allows support for Bluetooth headset with BT41 dongle</li>
      <li>Up to 16 SIP accounts</li>
      <li>Paper label-free design</li>
      <li>Local phonebook with up to 1,000 entries</li>
      <li>RJ9 headset port with EHS support (see EHS36 wireless headset adapter)</li>
      <li>Supports up to 6 EXP40 expansion modules</li>
      <li>Stand with 2 adjustable angles</li>
      <li>Wall mountable (with kit WMB-T46)</li>
      <li>PoE 802.3af</li>
      <li>Power supply not included (PS5V2000US)</li>
      <li>Upgrades from T46G</li>
      <li>Device performance enhancement with a faster response on the phone's user interface</li>
      <li>Opus support</li>
      <li>Enhanced speaker performance</li>
      <li>United firmware</li>
      <li>Hearing aid-compatible handset</li>
      <h3> $7.95 
 per unit, per month</h3>
 
      </ul>
        </div> 
        <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Yealink YEA-VP59G Flagship Smart Video Phone
</h2>
<h3>Revolutionize your video meetings with the Yealink YEA-VP59G Flagship Smart Video Phone
</h3>
</div>
<div className='voip__bx__flexb'>
<img  src={y59g} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$24.95-Per Month</h3>

<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong8">See Details</motion.button>



<div class="modal fade" id="exampleModalLong8" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={y59g} alt='circle2'/>
<div className='modal__list'>
<h2>Yealink YEA-VP59G Flagship Smart Video Phone</h2>
<h3>SKU: YEA-VP59G</h3>
<ul>
<li>8-inch (1280 x 800) capacitive adjustable touchscreen</li>
<li>1080p@30 full HD video</li>
<li>Runs Android 7.1</li>
<li>Built-in Bluetooth 4.2</li>
<li>Built-in dual band 2.4G/5G Wi-Fi (802.11a/b/g/n/ac)</li>
<li>USB 2.0 port for USB headset, media and storage applications</li>
<li>Standard HDMI output for monitor connection</li>
<li>Up to 16 VoIP accounts</li>
<li>Up to 3-party video conferencing</li>
<li>Up to 5-party video/audio mixed conferencing</li>
<li>PoE support</li>
<li>Power supply not included</li>
<h3> $24.95 
 per unit, per month</h3>
</ul>

</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>
    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Yealink DECT Conference Phone</h2>
          <h3>Upgrade your workspace with the Yealink Entry Level Gigabit POE 2-Line HD Voice Phone
 </h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={dcphone} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$18.95-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong9">See Details</motion.button>



<div class="modal fade" id="exampleModalLong9" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={dcphone} alt='circle2'/>
      <div className='modal__list'>
      <h2>Yealink DECT Conference Phone</h2>
      <h3>SKU: YEA-CP930W</h3>
      <ul>
      <li>3.1” 248x120-pixel graphical LCD with backlight</li>
      <li>Built-in rechargeable Lithium battery</li>
      <li>Up to 24-hour talk time</li>
      <li>Up to 15-day standby time</li>
      <li>3.75-hour charging time</li>
      <li>Built-in 3-microphone array</li>
      <li>Optima HD voice, full duplex technology</li>
      <li>20-foot (6-meter) and 360-degree microphone pickup range</li>
      <li>Yealink Noise Proof technology</li>
      <li>5-way conference call</li>
      <li>Connect to PC via Micro USB port</li>
      <li>Connect to mobile phones via Bluetooth</li>
      <li>Hybrid UC Meeting</li>
      <li>Call recording via Micro SD card</li>
      <li>Sensitive touch keypad</li>
      <li>Upgrade Over-the-Air (OTA)</li>
      <li>Can operate alone for applications such as Bluetooth or Micro USB into computer</li>
      <li>Must work in conjunction with Yealink W60B Base Station for SIP operation.</li>
      <h3> $18.95 
 per unit, per month</h3>
      </ul>

        </div> 
      <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Yealink Entry Level Gigabit POE 2-Line HD Voice</h2>
<h3>Maximize your workspace efficiency with the Yealink Entry Level Gigabit POE Phone
 </h3>
</div>
<div className='voip__bx__flexb'>
<img  src={poe2} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$3.95-Per Month</h3>

<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong10">See Details</motion.button>



<div class="modal fade" id="exampleModalLong10" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={poe2} alt='circle2'/>
<div className='modal__list'>
<h2>Yealink YEA-VP59G Flagship Smart Video Phone</h2>
<h3>SKU: YEA-SIP-T31G</h3>
<ul>
<li>Yealink HD Voice</li>
<li>2.3” 132x64-pixel graphical LCD with backlight</li>
<li>Dual-port Gigabit Ethernet</li>
<li>PoE support</li>
<li>Opus codec support</li>
<li>Up to 2 SIP accounts</li>
<li>Local 5-way conferencing</li>
<li>Support EHS Wireless Headset</li>
<li>Unified Firmware</li>
<li>Support YDMP/YMCS</li>
<li>Stand with 2 adjustable angles</li>
<li>Wall mountable</li>
<h3> $3.95 
 per unit, per month</h3>
</ul>

</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>

    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Yealink Entry Level Gigabit POE Color LCD 4-Line</h2>
          <h3>Get the best of both worlds with the Yealink SIP-T58W Smart Media Android HD Phone
</h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={lcd4} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$4.95-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong11">See Details</motion.button>



<div class="modal fade" id="exampleModalLong11" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={lcd4} alt='circle2'/>
      <div className='modal__list'>
      <h2>Yealink Entry Level Gigabit POE Color LCD 4-Line</h2>
      <h3>SKU: YEA-SIP-T33G</h3>
      <ul>
      <li>Yealink HD Voice</li>
      <li>2.4” 320 x 240-pixel color display with backlight</li>
      <li>Dual-port Gigabit Ethernet</li>
      <li>PoE support</li>
      <li>Opus codec support</li>
      <li>Up to 4 SIP accounts</li>
      <li>Local 5-way conferencing</li>
      <li>Support EHS Wireless Headset</li>
      <li>Unified Firmware</li>
      <li>Support YDMP/YMCS</li>
      <li>Stand with 2 adjustable angles</li>
      <li>Wall mountable</li>
      <h3> $4.95 
 per unit, per month</h3>
      </ul>

        </div> 
      <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Yealink SIP-T58W Smart Media Android HD Phone</h2>
<h3>Get the best of both worlds with the Yealink SIP-T58W Smart Media Android HD Phone
 </h3>
</div>
<div className='voip__bx__flexb'>
<img  src={t58w} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$14.95-Per Month</h3>


<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong12">See Details</motion.button>


<div class="modal fade" id="exampleModalLong12" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={t58w} alt='circle2'/>
<div className='modal__list'>
<h2>Yealink SIP-T58W Smart Media Android HD Phone</h2>
<h3>SKU: YEA-SIP-T58W</h3>
<ul>
<li>Runs Android 9.0</li>
<li>7-inch (1024 x 600) capacitive adjustable touchscreen</li>
<li>Supports Yealink Bluetooth Handset BTH58 (Optional)</li>
<li>720p30 HD video (with CAM50)</li>
<li>Optimal HD audio, Yealink Noise Proof Technology</li>
<li>Yealink’s SDK (Software Development Kit)</li>
<li>DoorPhone Features</li>
<li>(Preview, One-button Open, Monitoring)</li>
<li>Built-in Bluetooth 4.2 and Dual Band 2.4G/5G Wi-Fi</li>
<li>2 x USB 2.0 ports for USB headset, media and storage</li>
<li>applications</li>
<li>Opus Codec support</li>
<li>Up to 16 SIP accounts</li>
<li>Up to 10-party audio conferencing</li>
<li>Dual-port Gigabit Ethernet, supports PoE</li>
<li>Wall mountable</li>
<li>Supports up to 3 Expansion Module EXP50</li>
<li>Supports Yealink/Unify Square Device Management</li>
<li>Platform</li>
<h3> $14.95 
 per unit, per month</h3>
</ul>

</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>

    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Yealink T4 series Expansion Module</h2>
          <h3>Expand your workspace capabilities with the Yealink T4 series Expansion Module</h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={t43u} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$3.63-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong13">See Details</motion.button>



<div class="modal fade" id="exampleModalLong13" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={t43u} alt='circle2'/>
      <div className='modal__list'>
      <h2>Yealink T4 series Expansion Module</h2>
      <h3>SKU: YEA-EXP40</h3>
      <ul>
      <li>Expansion module for the YEA-SIP-T46G, T46S, T48G and T48S models</li>
      <li>Rich visual experience with 160x320 graphic LCD</li>
      <li>20 physical keys each with a dual-color LED</li>
      <li>2 independent control keys are used for fast-switch pages</li>
      <li>Stand with 2 adjustable angles</li>
      <li>Wall mountable</li>
      <li>160x320 graphic LCD with 16-level grayscales</li>
      <li>LCD Backlight</li>
      <li>Two-page views are possible</li>
      <li>Different icons for each function are shown on the LCD</li>
      <li>20 physical keys each with a dual-color LED</li>
      <li>20 additional keys through the page switch</li>
      <li>2 independent control keys are used for fast-switch pages</li>
      <li>Programmable for Shared Line, BLF List, BroadSoft Group, Call Park, Conference, Forward, Group pickup Group listening, LDAP, Pick UP, XML Browser, Zero-SP-Touch</li>
      <li>Wall mountable</li>
      <li>Stand with 2 adjustable angles</li>
      <li>Expansion module (=2) is powered by the host phone</li>
      <li>Supports up to 6 modules of daisy-chain</li>
      <li>2xRJ12(6P6C)ports for data in and out</li>
      <li>This applies to Yealink IP Phone</li>
      <li>Operating temperature: -10~50°C</li>
      <li>Operating humidity: 10-95%</li>
      <h3>$3.63 per unit, per month</h3>
      </ul>

        </div> 
      <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Yealink T40G IP POE Phone</h2>
<h3>Experience enhanced communication with the Yealink T40G IP POE Phone
 </h3>
</div>
<div className='voip__bx__flexb'>
<img  src={t40g} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$4.95-Per Month</h3>


<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong14">See Details</motion.button>



<div class="modal fade" id="exampleModalLong14" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={t40g} alt='circle2'/>
<div className='modal__list'>
<h2>Yealink T40G IP POE Phone</h2>
<h3>SKU: YEA-SIP-T40G</h3>
<ul>
<li>Revolutionary new design</li>
<li>Yealink Optima HD voice</li>
<li>2.3” 132x64-pixel graphical LCD with backlight</li>
<li>Dual-port Gigabit Ethernet</li>
<li>Up to three SIP accounts</li>
<li>Paper label-free design</li>
<li>Opus codec support</li>
<li>PoE support</li>
<li>Headset, electronic hook switch (EHS) support</li>
<li>Stand with two adjustable angles</li>
<li>Wall mountable</li>
<li>Simple, flexible and secure provisioning options</li>
<h3> $4.95 
 per unit, per month</h3>
</ul>

</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>

    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Yealink T41S IP Desk Phone</h2>
          <h3>Stay connected in style with the Yealink T41S IP Desk Phone
 </h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={t41s} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$5.95-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong15">See Details</motion.button>



<div class="modal fade" id="exampleModalLong15" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={t41s} alt='circle2'/>
      <div className='modal__list'>
      <h2>Yealink T41S IP Desk Phone</h2>
      <h3>SKU: YEA-SIP-T41S</h3>
      <ul>
      <li>Yealink Optima HD Voice</li>
      <li>Codecs: Opus, G.722, G.711 (A/u), G.723.1, G.729AB, G.726</li>
      <li>Display: 2.7" 192x64 pixel graphical LCD with backlight</li>
      <li>Up to 6 SIP accounts</li>
      <li>6 Line keys with dual color LEDs can be programmed with up to 15 features (3 page view)</li>
      <li>4 Soft Keys</li>
      <li>Paper label-free design</li>
      <li>One RJ9 headset port with EHS support (with EHS36 wireless headset adapter)</li>
      <li>Wall mountable with WMB-T4S kit</li>
      <li>Two 10/100M Ethernet ports</li>
      <li>PoE 802.3af, class 2</li>
      <li>No power supply included (PS5V1200US)</li>
      <li>Upgrades from T41P</li>
      <li>Device performance enhancement with a faster response on the phone's user interface</li>
      <li>Opus support</li>
      <li>Enhanced speaker performance</li>
      <li>USB port for Bluetooth headset with BT41 dongle or WiFi support with WF40 dongle</li>
      <li>United firmware</li>
      <li>Hearing aid-compatible handset</li>
      <h3> $5.95 
 per unit, per month</h3>
      </ul>

        </div> 
      <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Yealink T46U</h2>
<h3>Stay connected with ease with the Yealink T46U IP Phone
 </h3>
</div>
<div className='voip__bx__flexb'>
<img  src={t46u} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$5.95-Per Month</h3>

<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong16">See Details</motion.button>



<div class="modal fade" id="exampleModalLong16" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={t46u} alt='circle2'/>
<div className='modal__list'>
<h2>Yealink T46U</h2>
<h3>SKU: YEA-SIP-T46U</h3>
<ul>
<li>4.3" 480 x 272-pixel color display with backlight</li>
<li>Opus codec support</li>
<li>Dual USB ports</li>
<li>Dual firmware images</li>
<li>T42U (pending)/T43U/T46U/T48U Auto-P template unified</li>
<li>T42U (pending)/T43U/T46U/T48U firmware unified</li>
<li>Up to 16 SIP accounts</li>
<li>Dual-port Gigabit Ethernet</li>
<li>PoE support</li>
<li>USB headset and EHS support</li>
<li>Bluetooth via BT40/BT41</li>
<li>USB recording</li>
<li>Supports color-screen expansion modules</li>
<li>Stand with two adjustable angles</li>
<li>Wall mountable</li>
<li>Expandable with YEA-EXP43</li>
<li>PSU (YEA-PS5V2000US) not included</li>
<li>Does not support EHS36 and EXP40</li>
<h3>  $5.95   per unit, per month</h3>
</ul>

</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>
    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Yealink T42S Gigabit IP Phone</h2>
          <h3>Elevate your communication with the Yealink T42S Gigabit IP Phone
</h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={t42s} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$6.95-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong17">See Details</motion.button>



<div class="modal fade" id="exampleModalLong17" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={t42s} alt='circle2'/>
      <div className='modal__list'>
      <h2>Yealink T42S Gigabit IP Phone</h2>
      <h3>SKU: YEA-SIP-T42S</h3>
      <ul>
      <li>Yealink Optima HD Voice</li>
      <li>Dual port Gigabit Ethernet</li>
      <li>Codecs: Opus, G.722, G.711 (A/u), G.723.1, G.729AB, G.726, iLBC</li>
      <li>Display: 2.7" 192x64 pixel graphical LCD with backlight</li>
      <li>Up to 12 SIP accounts</li>
      <li>6 Line keys with dual color LEDs can be programmed with up to 15 features (3-page view)</li>
      <li>4 Soft Keys</li>
      <li>Paper label-free design</li>
      <li>One RJ9 headset port with EHS support (with EHS36 wireless headset adapter)</li>
      <li>Integrated stand with 2 adjustable angles</li>
      <li>Wall mountable with WMB-T4S kit</li>
      <li>PoE 802.3af, class 2</li>
      <li>No power supply included (PS5V1200US)</li>
      <li>Upgrades from T42G</li>
      <li>Device performance enhancement with faster response on the phone's user interface</li>
      <li>Opus support</li>
      <li>Enhanced speaker performance</li>
      <li>USB port for Bluetooth headset with BT41 dongle or WiFi support with WF40 dongle</li>
      <li>United firmware</li>
      <li>Hearing aid-compatible handset</li>
      <h3> $6.95 
  per unit, per month</h3>
      </ul>

        </div> 
      <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Yealink Ultra-Elegant Touchscreen</h2>
<h3>Experience the future of communication with the Yealink Ultra-Elegant Touchscreen Phone
 </h3>
</div>
<div className='voip__bx__flexb'>
<img  src={t48u} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$11.95-Per Month</h3>

<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong18">See Details</motion.button>



<div class="modal fade" id="exampleModalLong18" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={t48u} alt='circle2'/>
<div className='modal__list'>
<h2>Yealink Ultra-Elegant Touchscreen</h2>
<h3>YEA-SIP-T48S</h3>
<ul>
<li>Yealink Optima HD voice</li>
<li>Codecs: Opus, G.722, G.711(A/u), G.723, G.729AB, G.726, iLBC</li>
<li>Dual-port Gigabit Ethernet</li>
<li>Display: 7" 800x480 pixel color touch screen with backlight</li>
<li>29 one-touch DSS keys</li>
<li>A built-in USB port allows support for Bluetooth headset with BT41 dongle or WiFi access with WF40 dongle</li>
<li>Up to 16 SIP accounts</li>
<li>Paper label-free design</li>
<li>Local phonebook with up to 1,000 entries</li>
<li>RJ9 headset port with EHS support (see EHS36 wireless headset adapter)</li>
<li>Supports up to 6 EXP40 expansion modules</li>
<li>Wall mountable (with kit WMB-T48)</li>
<li>PoE 802.3af</li>
<li>Power supply not included (PS5V2000US)</li>
<li>Upgrades from T48G</li>
<li>Device performance enhancement with a faster response on the phone's user interface</li>
<li>Opus support</li>
<li>Enhanced speaker performance</li>
<li>United firmware</li>
<li>Hearing aid-compatible handset</li>
<h3> $11.95 
 per unit, per month</h3>
</ul>

</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>

    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Yealink Ultra-Elegant Touchscreen</h2>
          <h3>Experience the future of communication with the Yealink Ultra-Elegant Touchscreen Phone
</h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={t48u} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$9.95-Per Month</h3>


          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong19">See Details</motion.button>



<div class="modal fade" id="exampleModalLong19" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={t48u} alt='circle2'/>
      <div className='modal__list'>
      <h2>Yealink Ultra-Elegant Touchscreen</h2>
      <h3>SKU: YEA-SIP-T48U</h3>
      <ul>
      <li>7" 800 x 480-pixel color touch screen with backlight</li>
      <li>Opus codec support</li>
      <li>Dual USB ports</li>
      <li>Dual firmware images</li>
      <li>T4U Auto-P template unified</li>
      <li>T4U firmware unified</li>
      <li>Up to 16 SIP accounts</li>
      <li>Dual-port Gigabit Ethernet</li>
      <li>PoE support</li>
      <li>Paperless label design</li>
      <li>Headset, EHS support</li>
      <li>Wi-Fi via WF40/WF50</li>
      <li>Bluetooth via BT40/BT41</li>
      <li>USB recording</li>
      <li>Supports color-screen expansion modules</li>
      <li>Stand with two adjustable angles</li>
      <li>Wall mountable</li>
      <h3>$9.95 per unit, per month</h3>

        </ul>
        </div> 
      <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Cisco IP Phone 8861 with Multiplatform</h2>
<h3>Upgrade your workspace with the Cisco IP Phone 8861 with Multiplatform support.
 </h3>
</div>
<div className='voip__bx__flexb'>
<img  src={c8861} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$17.95-Per Month</h3>

<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong20">See Details</motion.button>



<div class="modal fade" id="exampleModalLong20" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={c8861} alt='circle2'/>
<div className='modal__list'>
<h2>Cisco IP Phone 8861 with Multiplatform</h2>
<h3>SKU: CIS-CP-8861-3PCC-K9 </h3>
<ul>
<li>The Cisco IP Phone 8861 offers a 5-in. high-resolution (800 x 480) widescreen VGA backlit color display</li>
<li>Localized language support, including right-to-left on-screen text, meets the needs of global users</li>
<li>The phone offers a built-in Gigabit Ethernet switch for both network connection and your PC connection</li>
<li>The phone also supports campuses with 802.11a/b/g/n/ac WLAN enabled</li>
<li>Support for Cisco EnergyWise™ technology makes the Cisco IP Phone 8861 more energy-efficient and ecofriendly; the phone is qualified by the Energy Star organization</li>
<li>An optional wall-mount kit is orderable as a spare part for customers seeking this capability</li>
<li>Up to three optional IP Phone, 8800 Key Expansion Modules can be supported for 108 additional line/feature keys</li>
<h3> $17.95 
 per unit, per month</h3>

</ul>
</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>
    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Cisco IP Phone 8851 with Multiplatform</h2>
          <h3>Maximize your productivity with the Cisco IP Phone 8851 with Multiplatform support </h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={c8851} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$11.95-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong21">See Details</motion.button>



<div class="modal fade" id="exampleModalLong21" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={c8851} alt='circle2'/>
      <div className='modal__list'>
      <h2>Cisco IP Phone 8851 with Multiplatform</h2>
      <h3>SKU: CIS-CP-8851-3PCC-K9</h3>
      <ul>
        </ul>
      <h3> $11.95 
 per unit, per month</h3>
        </div> 
      <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Cisco IP Phone 8841 with Multiplatform</h2>
<h3>Stay connected on-the-go with the Cisco IP Phone 8841 with Multiplatform support </h3>
</div>
<div className='voip__bx__flexb'>
<img  src={c8841} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$11.95-Per Month</h3>

<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong22">See Details</motion.button>



<div class="modal fade" id="exampleModalLong22" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={c8841} alt='circle2'/>
<div className='modal__list'>
<h2>Cisco IP Phone 8841 with Multiplatform</h2>
<h3>SKU: CIS-CP-8841-3PCC-K9</h3>
<ul>
<li>The Cisco IP Phone 8841 offers a 5-in. high-resolution (800 x 480) widescreen VGA backlit color display. Localized language support, including right-to-left on-screen text, meets the needs of global users</li>
<li>The phone supports a built-in Gigabit Ethernet switch for your PC connection</li>
<li>Support for Cisco EnergyWise™ technology makes the Cisco IP Phone 8841 more energy-efficient and ecofriendly; the phone is qualified by the Energy Star organization</li>
<li>An optional wall-mount kit is orderable as a spare part for customers seeking this capability</li>
<h3> $11.95 
 per unit, per month</h3>
</ul>

</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>
    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Cisco IP Phone 8811 with Multiplatform</h2>
          <h3>Experience enhanced communication with the Cisco IP Phone 8811 with Multiplatform support </h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={c8811} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$10.95-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong23">See Details</motion.button>



<div class="modal fade" id="exampleModalLong23" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={c8811} alt='circle2'/>
      <div className='modal__list'>
      <h2>Cisco IP Phone 8811 with Multiplatform</h2>
      <h3>SKU: CIS-CP-8811-3PCC-K9</h3>
      <ul>
        <li>The Cisco IP Phone 8811 offers a 5-in. high-resolution (800 x 480) widescreen backlit grayscale display. Localized language support, including right-to-left on-screen text, meets the needs of global users</li>
        <li>The phone supports a built-in Gigabit Ethernet switch for your PC connection</li>
        <li>Support for Cisco EnergyWise™ technology makes the Cisco IP Phone 8811 more energy-efficient and ecofriendly; the phone is qualified by the Energy Star organization</li>
        <li>An optional wall-mount kit is orderable separately for customers seeking this capability
        </li>
        <h3> $10.95 
 per unit, per month</h3>
        </ul>

        </div> 
      <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Cisco IP Phone 7861 for 3rd Party</h2>
<h3>Elevate your communication with the Cisco IP Phone 7861
 </h3>
</div>
<div className='voip__bx__flexb'>
<img  src={c7861} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$9.95-Per Month</h3>

<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong24">See Details</motion.button>



<div class="modal fade" id="exampleModalLong24" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={c7861} alt='circle2'/>
<div className='modal__list'>
<h2>Cisco IP Phone 7861 for 3rd Party</h2>
<h3>SKU: CIS-CP-7861-3PCC-K9 </h3>
<ul>
<li>Sixteen line and programmable keys offer quick access to features and staff</li>
<li>High-resolution, graphical, grayscale display for easier viewing</li>
<li>Dedicated fixed keys** for more efficient communications</li>
<li>Built-in speakerphone enables hands-free communication</li>
<li>Wideband audio enhances clarity when using a handset, speaker or a headset
</li>
<li>Flexible deployment options include Cisco on-premises, hosted, and Webex Calling
</li>
<li>Cisco Expressway enables remote workers single sign-on access without a VPN client
</li>
<li>Power over Ethernet (PoE) Class 1 and Cisco EnergyWise to help reduce energy costs
</li>
<li>Integrated IEEE 10/100 switch to cut installation costs
</li>
<li>Cisco Webex Phone OS consolidates administration with common software to both the IP Phone 7800 Series and IP Phone 8800 Series
</li>
<h3> $9.95 
 per unit, per month</h3>
</ul>

</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>

    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Cisco IP Phone 7841 with Multiplatform</h2>
          <h3>Stay connected in style with the Cisco IP Phone 7841 with Multiplatform support </h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={c7841} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$12.95-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong25">See Details</motion.button>



<div class="modal fade" id="exampleModalLong25" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={c7841} alt='circle2'/>
      <div className='modal__list'>
      <h2>Cisco IP Phone 7841 with Multiplatform</h2>
      <h3>SKU: CIS-CP-7841-3PCC-K9 </h3>
      <ul>
        <li>Four line and programmable feature keys enable quick access to telephony features and staff</li>
        <li>High-resolution, graphical grayscale display makes viewing easier</li>
        <li>Dedicated fixed keys ease communications for increased efficiency</li>
        <li>Built-in speakerphone adds freedom with hands-free communications</li>
        <li>Wideband for enhanced audio clarity is supported with the handset, speaker, or a headset</li>
        <li>Flexible deployment options include Cisco on-premises, hosted, and Webex Calling</li>
        <li>Cisco Expressway supports remote worker single sign-on access without the need for a VPN client</li>
        <li>Power over Ethernet (PoE) Class 1 and Cisco EnergyWise help reduce energy costs</li>
        <li>Integrated IEEE 10/100/1000 switch lowers installation costs and footprint at the desk</li>
        <li>Cisco Webex Phone OS consolidates administration with common software to both the IP Phone 7800 Series and IP Phone 8800 Series</li>
        <h3> $12.95 
 per unit, per month</h3>

        </ul>
        </div> 
      <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Cisco IP Phone 7821 with Multiplatform</h2>
<h3>Maximize your productivity with the Cisco IP Phone 7821 with Multiplatform support </h3>
</div>
<div className='voip__bx__flexb'>
<img  src={c7821} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$5.95-Per Month</h3>

<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong26">See Details</motion.button>



<div class="modal fade" id="exampleModalLong26" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={c7821} alt='circle2'/>
<div className='modal__list'>
<h2>Cisco IP Phone 7821 with Multiplatform</h2>
<h3>SKU: CIS-CP-7821-3PCC-K9</h3>
<ul>
<li>Two-lines deliver more efficient call handling</li>
<li>High-resolution graphical grayscale display makes viewing easier</li>
<li>Dedicated fixed keys* ease communications for increased productivity</li>
<li>Built-in speakerphone supports hands-free communications</li>
<li>Wideband audio enhances clarity with the handset, speaker, or a headset</li>
<li>Flexible deployment options with Cisco on-premises, hosted, and Webex Calling</li>
<li>Cisco Expressway supports remote worker single sign-on access without a VPN client</li>
<li>Power over Ethernet (PoE) Class 1 and Cisco EnergyWise lowers energy costs</li>
<li>Integrated IEEE 10/100 switch reduces installation costs and footprint at the desk</li>
<li>Cisco Webex Phone OS consolidates administration with common software to both the IP Phone 7800 Series and IP Phone 8800 Series</li>
<h3> $5.95 
 per unit, per month</h3>

</ul>
</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>

    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Cisco IP Phone 7811 with Multiplatform</h2>
          <h3>Stay connected on-the-go with the Cisco IP Phone 7811 with Multiplatform support
</h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={c7811} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$4.95-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong27">See Details</motion.button>



<div class="modal fade" id="exampleModalLong27" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={c7811} alt='circle2'/>
      <div className='modal__list'>
      <h2>Cisco IP Phone 7811 with Multiplatform</h2>
      <h3>SKU: CIS-CP-7811-3PCC-K9</h3>
      <ul>
       <li>High-resolution, graphical grayscale display for easier viewing</li>
       <li>Dedicated fixed keys for increased efficiency</li>
       <li>Built-in speakerphone for hands-free communications</li>
       <li>Wideband for enhanced audio, supported with an optional wideband handset</li>
       <li>Flexible deployment options include Cisco on-premises, hosted, and Webex Calling</li>
       <li>Cisco Expressway, which enables remote workers to enjoy single sign-on access without a VPN client</li>
       <li>Power over Ethernet (PoE) Class 1 and Cisco EnergyWise to help reduce energy costs</li>
       <li>Integrated IEEE 10/100 switch to reduce installation costs</li>
       <li>Cisco Webex Phone OS consolidates administration with software common to both the IP Phone 7800 Series and IP Phone 8800 Series
</li>
<h3>$4.95 per unit, per month</h3>

        </ul>
        </div> 
      <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>Cisco IP Conference Phone for MPP</h2>
<h3>Transform your conference calls with the Cisco IP Conference Phone for MPP </h3>
</div>
<div className='voip__bx__flexb'>
<img  src={ipconf} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$17.72-Per Month</h3>

<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong28">See Details</motion.button>



<div class="modal fade" id="exampleModalLong28" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={ipconf} alt='circle2'/>
<div className='modal__list'>
<h2>Cisco IP Conference Phone for MPP</h2>
<h3>SKU: CIS-CP-7832-3PCC-K9</h3>
<ul>
<li>Compact footprint design with 360-degree room coverage and no less than 7-ft (2.1-m) microphone pickup</li>
<li>3.4-inch (8.6-cm), 384x128-pixel monochrome LCD with backlit LED and antiglare bezel</li>
<li>Large mute button for easy access from all sides of the device</li>
<li>Single line with a user experience of multiple calls per line, as on other 7800 Series phones</li>
<li>Codec support including G.711(u/A), G.729a, G.729ab, iLBC, G.722, and OPUS</li>
<li>Secure Hash Algorithm (SHA)-256 enabled for advanced security features</li>
<li>IEEE 802.3af Power over Ethernet (PoE) Class 2 for low power consumption. An optional external universal AC power supply kit is available. The product is qualified by the Energy Star organization.</li>
<h3>$17.72 per unit, per month</h3>

</ul>
</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>



    <div className='row__phone'>
    <div className='voip__phones__col'>
      <div className='voip__bx'>

          <div className='voip__bx__flex'>
          <h2>Cisco 6851 Phone for MPP</h2>
          <h3>Experience enhanced communication with the Cisco 6851 Phone for MPP </h3>
          </div>
          <div className='voip__bx__flexb'>
          <img  src={c6851} alt='circle2'/> 
          <h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$7.95-Per Month</h3>

          <motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong29">See Details</motion.button>



<div class="modal fade" id="exampleModalLong29" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-body">
      <img  src={c6851} alt='circle2'/>
      <div className='modal__list'>
      <h2>Cisco 6851 Phone for MPP</h2>
      <h3>SKU: CIS-CP-6851-3PCC-K9 </h3>
      <ul>
         <li>4 programmable/line feature keys</li>
         <li>Gigabit integrated switch for colocated PC</li>
         <li>Power over Ethernet Class 2
</li>
         <li>Headset port</li>
         <li>Optional wideband handset</li>
         <li>Electronic Hookswitch headset control</li>
         <li>Optional key expansion module</li>
         <h3> $7.95 
 per unit, per month</h3>

        </ul>
        </div> 
      <button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  
      
      </div>
      
    </div>
  </div>
</div>  

          </div>       
      </div>
    </div>
    <div className='voip__phones__col'>
    <div className='voip__bx'>

<div className='voip__bx__flex'>
<h2>IP Video Phone with Android 6.0</h2>
<h3>Revolutionize your video meetings with the IP Video Phone with Android 6.0</h3>
</div>
<div className='voip__bx__flexb'>
<img  src={c8811} alt='circle2'/> 
<h3 style={{textAlign:"center",fontFamily:"Oswald",color:"#104E71",fontSize:"22px"}}>$11.10-Per Month</h3>

<motion.button whileTap={{scale:1.4}} data-toggle="modal" data-target="#exampleModalLong30">See Details</motion.button>



<div class="modal fade" id="exampleModalLong30" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
<div class="modal-content">
   <button type="button" class="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
<div class="modal-body">
<img  src={c8811} alt='circle2'/>
<div className='modal__list'>
<h2>IP Video Phone with Android 6.0</h2>
<h3>SKU: GS-GXV3370 </h3>
<ul>
<li>16 lines with up to 16 SIP accounts</li>
<li>Runs on AndroidTM 7.0 operating system with flexible SDK support for custom apps integrated WiFi & Bluetooth
</li>
<li>PoE/PoE+ to power device and provide network connection
</li>
<li>Speakerphone with HD acoustic chamber & echo cancellation
</li>
<li>7-inch capacitive 5-point touchscreen
</li>
<li>Strong security and encryption protocols
</li>
<h3>$11.10 per unit, per month</h3>
</ul>

</div> 
<button  id='contact__btn'><a href='/contact'>Contact Us</a></button>  

</div>

</div>
</div>
</div>  

</div>       
</div>
     </div>
    </div>

  </section>

<div id="fb-root"></div>

<div id="fb-customer-chat" class="fb-customerchat">
</div>
<Footer/>
</div>

  );
}

export default Voip;