import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  } from "react-router-dom";
  import React from 'react';
  
import main from './components/mainPage/main';
import contact from './components/contact/contact';
import bphonesystem from './components/bphonesystem/bphonesystem';
import esolution from './components/esolution/esolution';
import Whyvibes from './components/whyvibes/whyvibes';

import Productmessging from './components/productmessging/productmessging';
import Startup from './components/startups/startup';
import Blogs from './components/blogs/Blogs';
import Articles from './components/article/article';
import Privacy from './components/privacy/privacy';
import Resources from './components/resources/resources';
import hosted from './components/resources/hosted.pdf';
import Term from './components/Termandcondition/term';
import Articles2 from './components/article/article2';
import Articles3 from './components/article/article3';
import Articles4 from './components/article/article4';
import Articles5 from './components/article/article5';
import Articles6 from './components/article/article6';
import Articles7 from './components/article/article7';
import Articles8 from './components/article/article8';
import Articles9 from './components/article/article9';
import Articles10 from './components/article/article10';
import Smallbusinesssolution from './components/smallbusniessolution/Smallbusinesssolution';
import Articles11 from './components/article/article11';
import Articles12 from './components/article/article12';
import Voip from './components/voipphone/voip';
import Articles13 from './components/article/article13';
import Articles14 from './components/article/article14';
import Articles15 from './components/article/article15';
import Articles16 from './components/article/article16';
import Articles17 from './components/article/article17';
import Articles18 from './components/article/article18';
import Pricing from './components/Pricing/pricing';
import Landing from './components/land/Landing';


function App() {
  return (
    <>
      <>
     <Router>
      <Switch>

       <Route exact path="/" component={main} />
       <Route exact path="/contact" component={contact} /> 
       <Route exact path="/business-phone-system" component={bphonesystem} /> 
       <Route exact path="/solutions" component={esolution} /> 
      <Route exact path="/whyvibes" component={Whyvibes} /> 
      <Route exact path="/small-business-solution" component={Smallbusinesssolution} /> 
      <Route exact path="/team-chat-system" component={Productmessging} /> 
       <Route exact path="/startup" component={Startup} /> 
       <Route exact path="/blogs" component={Blogs} /> 
       <Route exact path="/voip-phone" component={Voip} /> 
       <Route exact path="/prices" component={Pricing} /> 
       
       <Route exact path="/blog/misconceptions-about-voip" component={Articles} /> 
       <Route exact path="/blog/voip-replace-traditional" component={Articles2} /> 
       <Route exact path="/blog/differnt-types-of-voip-services" component={Articles3} />
       <Route exact path="/blog/how-much-data-does-voip-use" component={Articles4} /> 
       <Route exact path="/blog/can-voip-phone-be-hacked" component={Articles5} />
       <Route exact path="/blog/a-to-z-voip-termination" component={Articles6} /> 
       <Route exact path="/blog/7-Feathers-to-Look-for-in-VoIP-Phone-Systems" component={Articles7} /> 
       <Route exact path="/blog/voip-numbers-explained" component={Articles8} />
       <Route exact path="/blog/owner-of-voip-number" component={Articles9} /> 
       <Route exact path="/blog/can-you-use-desk-phones-with-microsoft-teams" component={Articles10} />
       <Route exact path="/blog/getting-voip-services-for-your-home-office-7-features-you-well-need" component={Articles11} />
       <Route exact path="/blog/7-things-to-know-about-hosted" component={Articles12} />
       <Route exact path="/blog/SIP-vs-VoIP" component={Articles13} /> 
       <Route exact path="/blog/google-voice" component={Articles14} />
       <Route exact path="/blog/features-and-advantages-softphones" component={Articles15} /> 
       <Route exact path="/blog/digital-phone-vs-voip" component={Articles16} />
       <Route exact path="/blog/how-to-easily-set-up-multi-line" component={Articles17} /> 
       <Route exact path="/blog/pbx-vs-voip" component={Articles18} /> 
       <Route exact path="/privacy-policy" component={Privacy} /> 
       <Route exact path="/term-condoitions" component={Term} />
       <Route exact path="/resources" component={Resources} /> 
       <Route exact path="/hosted" component={hosted} /> 
       <Route exact path="/vibes" component={Landing} /> 

       
       <Redirect to="/" />
      </Switch>
    </Router>
    </>
    </>
  );
}

export default App;
